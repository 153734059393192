import {
  HeadingMedium,
  HeadingSmall,
  HeadingXLarge,
  HeadingXSmall,
  LabelLarge,
  ParagraphLarge,
  ParagraphMedium,
} from "baseui/typography";
import { Helmet } from "react-helmet";
import NavBarHome from "../NavBarHome/NavBarHome";
import CustomFormContainer from "./CustomFormContainer";
import { Accordion, Panel } from "baseui/accordion";
import AccessProfileSvg from "../../assets/img/svg/AccessProfileSvg";
import ReviewRecipt from "../../assets/img/svg/ReviewRecipt";
import PaymentMethodSvg from "../../assets/img/svg/PaymentMethodSvg";
import DownloadReciptSvg from "../../assets/img/svg/DownloadReciptSvg";
import HelpSvg from "../../assets/img/svg/HelpSvg";
import { Avatar } from "baseui/avatar";
import _ from "lodash";
import EncryptSvg from "../../assets/img/svg/EncryptSvg";
import SecureCloudSvg from "../../assets/img/svg/SecureCloudSvg";
import ExcellenceSecurity from "../../assets/img/svg/ExcellenceSecurity";
import FAQSection from "./FAQConfig";
const HomeConfigContent = (props) => {
  console.log(props, "props");
  const {
    orgId = _.get(props, "_id", ""),
    orgLogo = _.get(props, "logo", ""),
    backgroundImg = _.get(props, "cp_home.hero.bg_img", ""),
    backgroundColor = _.get(props, "cp_home.hero.bg_clr", "#f0f0f0"),
    subHeading = _.get(props, "cp_home.hero.cap", ""),
    headerColor = _.get(props, "customer_portal_ui.header_color", "#090708"),
    orgName = _.get(props, "name", ""),
    timeZone = _.get(props, "time_zone", ""),
    customHeading = _.get(props, "cp_home.hero.head", ""),
    rightsConfigContent = _.get(props, "customer_portal_ui.comi", ""),
    terms_and_conditions = _.get(props, "cp_home.term_u", ""),
    faq = _.get(props, "cp_home.faq", []),
    initialConfig = _.get(props, "hm_pg_conf", []),
    privacy = _.get(props, "cp_home.priv_u", ""),
    headerConfig = _.get(props, "cp_home.nav", []),
    alignment = _.get(props, "cp_home.hero.align", "left"),
    labelEmailorPhone = _.get(props, "hm_pg_emph_dn", ""),
    websiteUrl = _.get(props, "cp_home.web_url", ""),
    signIntext = _.get(
      props,
      "cp_home.hero.sign_txt",
      "Sign in now to find the option that’s right for you."
    ),
  } = props;

  console.log(initialConfig, props, terms_and_conditions, "backgroundImg");
  function hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
      );
    }
    throw new Error("Bad Hex");
  }

  function getTextColor(rgba) {
    rgba = rgba.match(/\d+/g);
    if (rgba[0] * 0.299 + rgba[1] * 0.587 + rgba[2] * 0.114 > 186) {
      return "#08083a";
    } else {
      return "white";
    }
  }

  const rgba = hexToRgbA(headerColor);

  const textColor = getTextColor(rgba);
  let faqArray = [];
  if (faq.length > 0) {
    faqArray = [
      {
        pos: "10",
        nam: "FAQ",
        url: "https://www.maxyfi.com/contact-us",
        tar: false,
      },
    ];
  }

  const filteredHeaderArray = [...headerConfig, ...faqArray];
  const footerConfig = [...headerConfig, ...faqArray];
  footerConfig.pop();
  return (
    <>
      <Helmet>
        <title>{orgName}</title>
        <link rel="icon" href={orgLogo} />
      </Helmet>
      <NavBarHome
        headerColor={headerColor}
        orgLogo={orgLogo}
        orgName={orgName}
        textColor={textColor}
        websiteUrl={websiteUrl}
        filteredHeaderArray={filteredHeaderArray}
      />

      {/* lp-content__modify */}
      <div
        className="lp-content-container"
        style={{
          // textAlign: alignment,
          backgroundImage: `url(${backgroundImg})`,
        }}
      >
        {/* lp-form-container__modify */}
        <div
          className="lp-form-container"
          style={{
            padding: "10px",
            opacity: "0.8",
            background: "rgb(0,0,0,0.6)",
            borderRadius: "8px",
            transition: "auto",
          }}
        >
          <HeadingXLarge
            $style={{ color: backgroundColor, lineHeight: "34px" }}
          >
            {customHeading}
          </HeadingXLarge>
          <ParagraphLarge
            $style={{
              color: backgroundColor,
              fontWeight: "500",
              fontSize: "18px",
            }}
          >
            {subHeading}
          </ParagraphLarge>
          <ParagraphLarge
            $style={{
              margin: "20px 0px 10px 0px",
              color: backgroundColor,
              fontSize: "15px",
            }}
          >
            {signIntext}
          </ParagraphLarge>
          <CustomFormContainer
            initialConfig={initialConfig}
            timeZone={timeZone}
            orgId={orgId}
            alignment={alignment}
            labelEmailorPhone={labelEmailorPhone}
            term_privacy={terms_and_conditions}
            privacy={privacy}
            rightsConfigContent={rightsConfigContent}
            textColor={backgroundColor}
          />
        </div>
      </div>

      <section
        className="separtor_landing_page"
        style={{ backgroundColor: "rgb(244, 249, 255)" }}
      >
        <HeadingSmall
          $style={{
            padding: "10px 0px",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Resolve Your account Easily - Follow these simple steps:{" "}
        </HeadingSmall>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            gap: "30px",
          }}
        >
          <div className="lp_data_info_container">
            <div className="step-home-details"> Step 1</div>
            {/* <SecureData /> */}
            <AccessProfileSvg size={60} color="rgb(51, 56, 96)" />

            <LabelLarge>Access Your Account: </LabelLarge>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ParagraphMedium>
                Click the link provided in your SMS, email, or letter.
              </ParagraphMedium>
              <ParagraphMedium>
                Or, sign in using your account details.
              </ParagraphMedium>
            </div>
          </div>
          <div className="lp_data_info_container">
            <div className="step-home-details"> Step 2</div>
            {/* <SecureData /> */}
            <ReviewRecipt size={60} color="rgb(51, 56, 96)" />

            <LabelLarge>Review Your Account: </LabelLarge>

            <ParagraphMedium>
              Carefully review your outstanding balance and payment details.
            </ParagraphMedium>
          </div>
          <div className="lp_data_info_container">
            <div className="step-home-details"> Step 3</div>
            {/* <SecureData /> */}
            <PaymentMethodSvg size={60} color="rgb(51, 56, 96)" />

            <LabelLarge>Choose Your Payment Method: </LabelLarge>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ParagraphMedium>
                Pay in Full: Make a one-time payment.
              </ParagraphMedium>

              <ParagraphMedium>
                Payment Plans: Select a convenient instalment plan that suits
                your budget.
              </ParagraphMedium>
            </div>
          </div>
          <div className="lp_data_info_container">
            <div className="step-home-details"> Step 4</div>
            {/* <SecureData /> */}
            <DownloadReciptSvg size={60} color="rgb(51, 56, 96)" />

            <LabelLarge>Download Your Receipt: </LabelLarge>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ParagraphMedium>
                Download and save a copy of your payment confirmation for your
                records.
              </ParagraphMedium>
            </div>
          </div>
          <div className="lp_data_info_container">
            <div className="step-home-details"> Request a Call Back</div>
            {/* <SecureData /> */}
            <HelpSvg size={55} color="rgb(51, 56, 96)" />

            <LabelLarge>Need Assistance? </LabelLarge>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ParagraphMedium>
                Our team will contact you shortly to answer any questions you
                may have.
              </ParagraphMedium>
            </div>
          </div>
        </div>
      </section>
      <section className="separtor_landing_page" style={{ paddingTop: "0px" }}>
        <div className="secure_content_faq_container">
          <div className="separtor_home_left_page">
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              <HeadingSmall>Your Data Is Secure With Us</HeadingSmall>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                gap: "30px",
              }}
            >
              <div className="lp_sub_content_data_secure">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "10px",
                    padding: "10px",
                  }}
                >
                  <EncryptSvg />
                  <LabelLarge>Advanced Encryption</LabelLarge>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <ParagraphMedium>
                    We utilize cutting-edge encryption protocols, including TLS
                    with AES (128-bit and higher) for secure data transmission
                    and dedicated unique keys for database encryption.
                  </ParagraphMedium>
                </div>
              </div>
              <div className="lp_sub_content_data_secure">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "10px",
                    padding: "10px",
                  }}
                >
                  <SecureCloudSvg />
                  <LabelLarge>Secure Cloud Infrastructure</LabelLarge>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <ParagraphMedium>
                    Your data is hosted on the highly secure Amazon AWS cloud
                    platform, compliant with rigorous standards such as SAS70,
                    ISO 27001, and PCI DSS Level 1.
                  </ParagraphMedium>
                </div>
              </div>
              <div className="lp_sub_content_data_secure">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "10px",
                    padding: "10px",
                  }}
                >
                  <ExcellenceSecurity />
                  <LabelLarge>Certified Security Excellence</LabelLarge>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <ParagraphMedium>
                    Our Software and vendor are SOC 2 Type 2 and ISO 27001
                    certified, underscoring our unwavering commitment to robust
                    security controls and comprehensive data protection.
                  </ParagraphMedium>
                </div>
              </div>
              {/* <div className="lp_data_info_container">
                <LabelLarge>Secure Cloud Infrastructure</LabelLarge>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <ParagraphMedium>
                    Your data is hosted on the highly secure Amazon AWS cloud
                    platform, compliant with rigorous standards such as SAS70,
                    ISO 27001, and PCI DSS Level 1.
                  </ParagraphMedium>
                </div>
              </div> */}
              {/* <div className="lp_data_info_container">
                <LabelLarge>Certified Security Excellence</LabelLarge>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <ParagraphMedium>
                    Our Software and vendor are SOC 2 Type 2 and ISO 27001
                    certified, underscoring our unwavering commitment to robust
                    security controls and comprehensive data protection.
                  </ParagraphMedium>
                </div>
              </div> */}
            </div>

            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                paddingLeft: "10px",
              }}
            >
              <LabelLarge>Advanced Encryption</LabelLarge>
              <ParagraphMedium>
                We utilize cutting-edge encryption protocols, including TLS with
                AES (128-bit and higher) for secure data transmission and
                dedicated unique keys for database encryption.
              </ParagraphMedium>
            </div> */}
            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                paddingLeft: "10px",
              }}
            >
              <LabelLarge>Secure Cloud Infrastructure</LabelLarge>
              <ParagraphMedium>
                Your data is hosted on the highly secure Amazon AWS cloud
                platform, compliant with rigorous standards such as SAS70, ISO
                27001, and PCI DSS Level 1.
              </ParagraphMedium>
            </div> */}
            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                paddingLeft: "10px",
              }}
            >
              <LabelLarge>Certified Security Excellence</LabelLarge>
              <ParagraphMedium>
                Our Software and vendor are SOC 2 Type 2 and ISO 27001
                certified, underscoring our unwavering commitment to robust
                security controls and comprehensive data protection.
              </ParagraphMedium>
            </div> */}
          </div>
        </div>
      </section>
      <section
        className="separtor_landing_page"
        style={{ paddingTop: "0px", backgroundColor: "rgb(244, 249, 255)" }}
      >
        <FAQSection faq={faq} />
      </section>

      <footer
        className="footer_home_v2"
        style={{
          backgroundColor: headerColor,
        }}
      >
        <svg
          class="footer-wave-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 100"
          preserveAspectRatio="none"
          fill={"rgb(244, 249, 255)"}
        >
          <path
            class="footer-wave-path"
            d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
          ></path>
        </svg>
        <div
          className="footer-landing-page"
          style={{
            backgroundColor: headerColor,
          }}
        >
          <div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Avatar
                src={orgLogo}
                size="100%"
                overrides={{
                  Root: {
                    style: () => ({
                      width: "50px",
                      height: "50px",
                      backgroundColor: backgroundColor,
                      //   marginTop: "4px",
                    }),
                  },
                  Initials: {
                    style: ({ $theme }) => ({
                      fontSize: "40px",
                    }),
                  },
                  Avatar: {
                    style: ({ $theme }) => ({
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      borderBottomRightRadius: "5px",
                    }),
                  },
                }}
                name={orgName}
              />
              <HeadingSmall $style={{ color: textColor ? textColor : "black" }}>
                {orgName}
              </HeadingSmall>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "25px",
              alignItems: "center",
            }}
          >
            {footerConfig
              .sort((a, b) => parseInt(a.pos) - parseInt(b.pos))
              .map((tab) => (
                <ParagraphLarge $style={{ color: textColor, fontSize: "14px" }}>
                  {/* <a
                    href="https://www.maxyfi.com/terms"
                    target="https://www.maxyfi.com/terms"
                  >
                    Terms of use
                  </a> */}

                  <a
                    key={tab.pos}
                    href={tab.url}
                    target={tab.tar ? "_blank" : "_self"}
                    rel="noopener noreferrer"
                    className="footer-copyright-link"
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor =
                        "rgba(255, 255, 255, 0.1)";
                    }}
                    onClick={(e) => {
                      if (tab.nam === "FAQ") {
                        e.preventDefault(); // Prevent default anchor behavior
                        document.getElementById("faq-section")?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }}
                  >
                    {tab.nam}
                  </a>
                </ParagraphLarge>
              ))}
          </div>
        </div>

        <div
          className="footer-landling-copy-rights"
          style={{
            textAlign: "center",
            padding: "10px",
            width: "100%",
            // backgroundColor: hexToRGBA(headerColor, 0.5),
            // opacity: "0.5",
            paddingTop: "10px",
            paddingBottom: "25px",
          }}
        >
          <a
            className="footer-copyright-link"
            href="#"
            target="_self"
            style={{ color: textColor ? textColor : "#ffffff" }}
          >
            {" "}
            {`Copyright © ${new Date().getFullYear()} ${
              orgName || "Your Company"
            }`}
            {/* ©2020. | Spring Oaks Capital,. | All rights reserved.{" "} */}
          </a>
        </div>
      </footer>
      {/* <div
          className="lp-header-container"
          style={{
            height: "150px",
            backgroundColor: headerColor,
          }}
        >
          <div
            className="lp-header-logo-container"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Avatar
                src={orgLogo}
                size="100%"
                overrides={{
                  Root: {
                    style: () => ({
                      width: "35px",
                      height: "35px",
                      //   marginTop: "4px",
                    }),
                  },
                  Initials: {
                    style: ({ $theme }) => ({
                      fontSize: "40px",
                    }),
                  },
                  Avatar: {
                    style: ({ $theme }) => ({
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      borderBottomRightRadius: "5px",
                    }),
                  },
                }}
                name={orgName}
              />
              <HeadingSmall>{orgName}</HeadingSmall>
            </div>
  
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <LabelMedium>Contact Us</LabelMedium>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {support_email}
                    {orgPhone?.code ? `${orgPhone?.code} ${orgPhone?.value}` : ""}
                    {orgPhone?.address ? `${orgPhone?.address}` : ""}
                  </div>
                </div>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <LabelMedium>Privacy</LabelMedium>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {support_email}
                    {orgPhone?.code ? `${orgPhone?.code} ${orgPhone?.value}` : ""}
                    {orgPhone?.address ? `${orgPhone?.address}` : ""}
                  </div>
                </div>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <LabelMedium>Terms Of Use</LabelMedium>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {term_privacy}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </>
  );
};
export default HomeConfigContent;
