import React from "react";

const ExcellenceSecurity = ({ size = "50" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9694 3H3.03025C1.91073 3 1 3.86425 1 4.92671V17.3839C1 18.4463 1.91073 19.3106 3.03025 19.3106H9.53972C9.73838 19.3106 9.89909 19.1581 9.89909 18.9695C9.89909 18.781 9.73838 18.6285 9.53972 18.6285H3.03025C2.30698 18.6285 1.71875 18.0703 1.71875 17.3839V4.92671C1.71875 4.24028 2.30698 3.68209 3.03025 3.68209H21.9694C22.6927 3.68209 23.2812 4.24028 23.2812 4.92671V17.3839C23.2812 18.0703 22.6927 18.6285 21.9694 18.6285C21.7708 18.6285 21.61 18.781 21.61 18.9695C21.61 19.1581 21.7708 19.3106 21.9694 19.3106C23.0889 19.3106 24 18.4463 24 17.3839V4.92671C24 3.86425 23.0889 3 21.9694 3Z"
        fill="black"
        stroke="black"
        stroke-width="0.25"
      />
      <path
        d="M8.90625 12.5493H4.59375C4.39512 12.5493 4.23438 12.7018 4.23438 12.8903C4.23438 13.0788 4.39512 13.2314 4.59375 13.2314H8.90625C9.10491 13.2314 9.26562 13.0788 9.26562 12.8903C9.26562 12.7018 9.10491 12.5493 8.90625 12.5493Z"
        fill="black"
        stroke="black"
        stroke-width="0.25"
      />
      <path
        d="M8.90625 14.7831H4.59375C4.39512 14.7831 4.23438 14.9356 4.23438 15.1241C4.23438 15.3126 4.39512 15.4652 4.59375 15.4652H8.90625C9.10491 15.4652 9.26562 15.3126 9.26562 15.1241C9.26562 14.9356 9.10491 14.7831 8.90625 14.7831Z"
        fill="black"
        stroke="black"
        stroke-width="0.25"
      />
      <path
        d="M19.593 15.9108C19.5864 15.8893 19.572 15.873 19.5616 15.8538C20.3076 15.0681 20.7656 14.0312 20.7656 12.8903C20.7656 10.4417 18.6739 8.45674 16.0937 8.45674C13.5135 8.45674 11.4218 10.4417 11.4218 12.8903C11.4218 14.0187 11.8697 15.0453 12.6011 15.8279C12.5936 15.8434 12.5816 15.8559 12.5765 15.8728L11.0646 20.792C11.0249 20.9212 11.0688 21.0601 11.1765 21.1474C11.2843 21.2346 11.4359 21.253 11.5636 21.1957L13.1506 20.4803L14.1477 21.8525C14.2154 21.9457 14.3267 22 14.4435 22C14.4639 22 14.4842 21.9983 14.5046 21.995C14.6429 21.9723 14.7545 21.8758 14.7906 21.7472L16.0401 17.3214C16.0582 17.3216 16.0756 17.3239 16.0937 17.3239C16.1023 17.3239 16.1105 17.3228 16.1191 17.3227L17.3687 21.7472C17.4049 21.8754 17.5161 21.972 17.654 21.995C17.6747 21.9983 17.6955 22 17.7158 22C17.8323 22 17.9429 21.9464 18.011 21.8538L19.0112 20.4883L20.5964 21.1963C20.7238 21.253 20.8751 21.234 20.9832 21.1467C21.0906 21.0595 21.1341 20.9209 21.0944 20.792L19.593 15.9108ZM16.0937 9.13883C18.2735 9.13883 20.0468 10.8217 20.0468 12.8903C20.0468 14.9589 18.2735 16.6419 16.0937 16.6419C13.914 16.6419 12.1406 14.9589 12.1406 12.8903C12.1406 10.8217 13.914 9.13883 16.0937 9.13883ZM14.2979 20.8576L13.5686 19.8541C13.4686 19.7166 13.2787 19.6673 13.1187 19.7396L11.9788 20.2535L13.1781 16.3514C13.7847 16.8122 14.515 17.1296 15.3142 17.2573L14.2979 20.8576ZM19.0424 19.7482C18.8834 19.6783 18.6943 19.7256 18.5942 19.8621L17.8625 20.8613L16.8458 17.2613C17.6453 17.1382 18.3762 16.825 18.9851 16.3686L20.1809 20.2568L19.0424 19.7482Z"
        fill="black"
        stroke="black"
        stroke-width="0.25"
      />
      <path
        d="M9.26562 6.75151C9.26562 6.37479 8.94384 6.06942 8.54688 6.06942H4.95312C4.5562 6.06942 4.23438 6.37479 4.23438 6.75151V10.162C4.23438 10.5387 4.5562 10.8441 4.95312 10.8441H8.54688C8.94384 10.8441 9.26562 10.5387 9.26562 10.162V6.75151ZM8.54688 10.162H4.95312V6.75151H8.54688V10.162Z"
        fill="black"
        stroke="black"
        stroke-width="0.25"
      />
      <path
        d="M16.0938 14.9366C17.2846 14.9366 18.25 14.0205 18.25 12.8903C18.25 11.7602 17.2846 10.8441 16.0938 10.8441C14.9029 10.8441 13.9375 11.7602 13.9375 12.8903C13.9375 14.0205 14.9029 14.9366 16.0938 14.9366ZM16.0938 11.5262C16.8864 11.5262 17.5312 12.1381 17.5312 12.8903C17.5312 13.6426 16.8864 14.2545 16.0938 14.2545C15.3011 14.2545 14.6562 13.6426 14.6562 12.8903C14.6562 12.1381 15.3011 11.5262 16.0938 11.5262Z"
        fill="black"
        stroke="black"
        stroke-width="0.25"
      />
    </svg>
  );
};

export default ExcellenceSecurity;
