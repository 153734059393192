import React, { useEffect } from "react";
import _ from "lodash";
import { useQuery } from "react-query";
import { getAuthDtl } from "../../services/customerPortal";

import { StyledLoadingSpinner } from "baseui/button";
import { useDispatch } from "react-redux";
import { defaultColor, getDateFormat } from "../../redux/Layout/action";
// import Secure from "../../assets/img/svg/landing-page/secure";
import HomeDefault from "./HomeDefaultContent";
import HomeContentAlternate from "./HomeContentAlternate";
import HomeConfigContent from "./HomeConfigContent";
// import Cloud from "../../assets/img/svg/landing-page/cloud";

const Home = () => {
  const dispatch = useDispatch();
  const getDomain = () => {
    const host = window.location.host;
    if (
      ["localhost", "mxfy.io", "dev-rls.mxfy.io"].some((domain) =>
        host.includes(domain)
      )
    ) {
      return "pay.maxyfi.com";
    }
    return host;
  };

  const { data, isError, isFetched, isLoading } = useQuery(
    [`GET_ORG_DTL_AUTH`],
    async () => {
      const domain = getDomain();
      return await getAuthDtl({ domain });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  useEffect(() => {
    if (isFetched && (isError || !data || !data.data || !data.data.doc)) {
      window.open("https://www.maxyfi.com", "_self");
    } else {
      const primary = _.get(
        data,
        "data.doc.organization.customer_portal_ui.primary_color"
      );

      dispatch(
        getDateFormat(
          _.get(data, "data.doc.organization.date_format", "DD/MM/YYYY")
        )
      );

      if (primary && primary.length > 0) {
        document.documentElement.style.setProperty("--primary", primary);
        dispatch(defaultColor(primary));
      }
    }
  }, [isFetched]);

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StyledLoadingSpinner />
      </div>
    );
  }
  const ConfigFieldsCheck = Object.keys(
    _.get(data, "data.doc.organization.cp_home", {})
  ).length;
  console.log(ConfigFieldsCheck, "ConfigFieldsCheck");
  switch (true) {
    case "auth":
      return (
        <HomeContentAlternate
          customHeading={
            "Resolve your dues in a Quick & Convenient way without speaking with a collector. "
          }
          headerColor={_.get(
            data,
            "data.doc.organization.customer_portal_ui.header_color"
          )}
          terms_and_conditions={
            "By entering the site, you are agreeing to the Terms of service and Privacy Policy"
          }
          rightsConfigContent={`This is a communication with a debt collector. This is an attempt
          to collect a debt and any information obtained will be used for
          that purpose.`}
          headerConfig={[
            {
              position: "1",
              name: "Home",
              url: "https://www.maxyfi.com/",
              is_new: true,
            },
            {
              position: "2",
              name: "About us",
              url: "https://www.maxyfi.com/About-us",
              is_new: true,
            },
            {
              position: "3",
              name: "Contact us",
              url: "https://www.maxyfi.com/contact-us",
              is_new: true,
            },
          ]}
          faq={[
            {
              question: "Access Your Account: ",
              answer: "Click the link provided in your SMS, email, or letter. ",
            },
            {
              question: "What features are included in the different slabs?",
              answer:
                "Each slab of our debt collection software offers a range of features designed to meet the diverse requirements of our users. Features include automated follow-ups, customizable templates, real-time reporting, and more. For a comprehensive list of features, please refer to our Pricing page.",
            },
            {
              question: "Can I switch plans at any time?",
              answer:
                "Yes, you have the flexibility to upgrade or downgrade your plan at any time. Simply log in to your account and navigate to the Billing section to make the necessary changes.",
            },
          ]}
          orgId={_.get(data, "data.doc.organization._id", "")}
          orgPhone={_.get(data, "data.doc.organization.name", {})}
          orgName={_.get(data, "data.doc.organization.name", "")}
          timeZone={_.get(data, "data.doc.organization.time_zone", "")}
          support_email={_.get(data, "data.doc.organization.support_email", "")}
          email={_.get(data, "data.doc.organization.email", "")}
          orgLogo={_.get(
            data,
            "data.doc.organization.customer_portal_ui.logo",
            ""
          )}
          initialConfig={_.get(data, "data.doc.organization.hm_pg_conf", [])}
          labelEmailorPhone={_.get(
            data,
            "data.doc.organization.hm_pg_emph_dn",
            ""
          )}
          headerCont={_.get(data, "data.doc.organization.prtl_hd_cont", "")}
          industrySector={_.get(
            data,
            "data.doc.organization.industry_sector",
            ""
          )}
          backgroundImgUrl={
            "https://media.geeksforgeeks.org/wp-content/cdn-uploads/20190710102234/download3.png"
          }
        />
      );

    case ConfigFieldsCheck > 0:
      return (
        <HomeConfigContent
          {...(data &&
            data?.data &&
            data?.data?.doc &&
            data?.data?.doc?.organization)}
        />
      );

    default:
      console.log(
        _.get(data, "data.doc.organization.date_format", "DD/MM/YYYY"),
        "OIUIOUOIUOIUOI"
      );
      return (
        <HomeDefault
          headerColor={_.get(
            data,
            "data.doc.organization.customer_portal_ui.header_color"
          )}
          orgId={_.get(data, "data.doc.organization._id", "")}
          orgName={_.get(data, "data.doc.organization.name", "")}
          timeZone={_.get(data, "data.doc.organization.time_zone", "")}
          email={_.get(data, "data.doc.organization.email", "")}
          orgLogo={_.get(
            data,
            "data.doc.organization.customer_portal_ui.logo",
            ""
          )}
          initialConfig={_.get(data, "data.doc.organization.hm_pg_conf", [])}
          labelEmailorPhone={_.get(
            data,
            "data.doc.organization.hm_pg_emph_dn",
            ""
          )}
          dateFormat={_.get(
            data,
            "data.doc.organization.date_format",
            "DD/MM/YYYY"
          )}
          headerCont={_.get(data, "data.doc.organization.prtl_hd_cont", "")}
          industrySector={_.get(
            data,
            "data.doc.organization.industry_sector",
            ""
          )}
        />
      );
  }
};

export default Home;
