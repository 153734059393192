import React from "react";

const PaymentMethodSvg = ({ color = "#5f6368", size = "48px" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 -960 960 960"
      width={size}
      fill={color}
    >
      <path d="M840-704.62v449.24q0 23.05-16.16 39.22Q807.67-200 784.62-200H175.38q-23.05 0-39.22-16.16Q120-232.33 120-255.38v-449.24q0-23.05 16.16-39.22Q152.33-760 175.38-760h609.24q23.05 0 39.22 16.16Q840-727.67 840-704.62ZM150.77-603.31h658.46v-101.31q0-9.23-7.69-16.92-7.69-7.69-16.92-7.69H175.38q-9.23 0-16.92 7.69-7.69 7.69-7.69 16.92v101.31Zm0 80.54v267.39q0 9.23 7.69 16.92 7.69 7.69 16.92 7.69h609.24q9.23 0 16.92-7.69 7.69-7.69 7.69-16.92v-267.39H150.77Zm0 292V-729.23-230.77Z" />
    </svg>
  );
};

export default PaymentMethodSvg;
