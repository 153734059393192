import React from "react";

const PaperPlane = ({ color = "#41a5ee", size = "24" }) => {
  return (
    <svg
      id="Layer_1"
      enable-background="new 0 0 512 512"
      height={size}
      viewBox="0 0 512 512"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_2_00000038385227688418196620000001652529180024542354_">
        <g id="Social_Icon">
          <path
            id="PaperPlane"
            d="m51.7 29.2c-38.8 0-63.8 41.3-45.7 75.7l66.2 125.6 222.1 25.5-222.1 25.6-66.2 125.6c-18.1 34.4 6.8 75.7 45.7 75.7 7 0 14-1.4 20.5-4.2l409.8-177c40-17.3 40-74 0-91.3l-409.8-177c-6.5-2.8-13.5-4.2-20.5-4.2z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default PaperPlane;
