import React, { useState } from "react";
import { LabelMedium } from "baseui/typography";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { TextBox } from "../../components/TextBox";
import { useIntl } from "react-intl";
import { Avatar } from "baseui/avatar";
import { useMutation } from "react-query";
import { TextButton } from "../../components/TextButton";
import { getConfigField, getHomePage } from "../../services/customerPortal";
import { toast } from "react-toastify";
import InputComponent from "../AuthorizeDownload/InputComponent";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PrimaryandSecondaryEducation } from "../../constants/IndustrialSector";
import Loader from "../../components/Loader/Loader";

const HomeDefault = ({
  orgId,
  orgLogo,
  dateFormat,
  headerColor,
  orgName,
  timeZone,
  email,
  initialConfig,
  headerCont,
  industrySector,
  labelEmailorPhone,
}) => {
  const intl = useIntl();
  const [isState, setState] = useState(false);
  const [isStoreAuth, setIsStoreAuth] = useState({});

  const [fieldValue, setFieldValue] = useState({
    email: "",
    phone: "",
  });

  let navigation = useNavigate();

  // const validationSchema = Yup.object().shape({
  //   phone: Yup.string()
  //     .matches(/^[0-9]+$/, "Phone number is not valid")
  //     .min(10, "Phone number must be at least 10 digits")
  //     .nullable()
  //     .notRequired(),
  //   email: Yup.string().email("Email is not valid").nullable().notRequired(),
  // });

  function hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
      );
    }
    throw new Error("Bad Hex");
  }

  function getTextColor(rgba) {
    rgba = rgba.match(/\d+/g);
    if (rgba[0] * 0.299 + rgba[1] * 0.587 + rgba[2] * 0.114 > 186) {
      return "#08083a";
    } else {
      return "white";
    }
  }

  const rgba = hexToRgbA(headerColor);

  const textColor = getTextColor(rgba);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    trigger,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(validationSchema),
  });

  const requestTime = useMutation((data) => getHomePage(data), {
    onError: (error, variables, context) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;
        toast.error("We couldn't find any matching records");
      }
    },
    onSuccess: (data, variables, context) => {
      reset();
      if (data && data.data) {
        setIsStoreAuth(data && data.data);
        setState(true);
      }
    },
  });

  const finalAuth = useMutation((data) => getConfigField(data), {
    onError: (error, variables, context) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;

        toast.error("We couldn't find any matching records");

        setState(false);
        setIsStoreAuth({});
        reset();
      }
    },
    onSuccess: (data, variables, context) => {
      reset();
      toast.success(data.data.message);
      window.open(data?.data?.url, "_self");
    },
  });

  const onSubmit = async (items) => {
    let keyCus = `${_.get(initialConfig, "[0].fm", "").toLowerCase()}_${
      initialConfig?.[0]?.fk
    }`;

    let fieldData = {
      phone: undefined,
      email: undefined,
    };

    const phonePattern = /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/; // Matches phone numbers like 123-456-7890, 123.456.7890, or 1234567890
    const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/; // Matches basic email patterns

    if (phonePattern.test(items.value)) {
      fieldData.phone = items?.value?.trim();
      clearErrors("value");
    } else if (emailPattern.test(items.value)) {
      fieldData.email = items?.value?.trim();
      clearErrors("value");
    }

    if (!isState && !items.value && !items[keyCus]) {
      setError("value", {
        type: "manual",
        message: "Either phone or email is required",
      });
      setError(`${keyCus}`, {
        type: "manual",
        message: `${initialConfig[0].lb} required`,
      });
      return;
    } else {
      clearErrors("value");
      clearErrors("email");
    }

    let { email, phone, ...rest } = items;

    const keys = Object.keys(rest);
    let isError = false;

    if (
      rest &&
      keys.length > 0 &&
      isStoreAuth &&
      Array.isArray(isStoreAuth.authorization)
    ) {
      for (let item of isStoreAuth.authorization) {
        const key = `${_.get(item, "fm", "").toLowerCase()}_${item.fk}`;
        const value = _.get(rest, key);

        if (!value) {
          setError(key, {
            type: "manual",
            message: "Required",
          });
          isError = true;
        } else {
          clearErrors(key);
        }
      }
    }

    if (isError) {
      return null;
    }

    const convertToTimestamp = (value) => {
      if (value instanceof Date) {
        return moment
          .tz(
            `${value ? value.getFullYear() : ""}-${
              value ? value.getMonth() + 1 : ""
            }-${value ? value.getDate() : ""}`,
            "YYYY-MM-DD",
            timeZone
          )
          .startOf("day")
          .utc()
          .valueOf();
      } else if (typeof value === "number") {
        return value.toString();
      }
      return value;
    };

    let dataInital = {
      search_val: {
        ...(items[keyCus] instanceof Date
          ? {
              [keyCus]: convertToTimestamp(items[keyCus]),
            }
          : { [keyCus]: items[keyCus] }),
      },
    };

    if (!isState) {
      let values = {
        ...fieldData,
        ...(dataInital && dataInital.search_val && dataInital.search_val[keyCus]
          ? { search_val: { [keyCus]: dataInital.search_val[keyCus] } }
          : {}),
        organizationId: orgId,
        queryParam: "CP_ORG_REFERER",
      };
      await requestTime.mutateAsync(values);
    } else {
      const convertToTimestamp = (value) => {
        if (value instanceof Date) {
          return moment
            .tz(
              `${value ? value.getFullYear() : ""}-${
                value ? value.getMonth() + 1 : ""
              }-${value ? value.getDate() : ""}`,
              "YYYY-MM-DD",
              timeZone
            )
            .startOf("day")
            .utc()
            .valueOf();
        } else if (typeof value === "number") {
          return value.toString();
        }
        return value;
      };

      for (const key in rest) {
        if (rest[key] instanceof Date) {
          rest[key] = convertToTimestamp(rest[key]);
        } else if (typeof rest[key] === "object" && !Array.isArray(rest[key])) {
          for (const objKey in rest[key]) {
            rest[`${key}.${objKey}`] = convertToTimestamp(rest[key][objKey]);
          }
          delete rest[key]; // Remove the nested object after flattening
        } else {
          rest[key] = convertToTimestamp(rest[key]);
        }
      }

      let { cus_custom_field, ...customField } = rest;

      await finalAuth.mutateAsync({
        ...customField,
        token: isStoreAuth && isStoreAuth.accessToken,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{orgName}</title>
        <link rel="icon" href={orgLogo} />
      </Helmet>
      <div
        className="header_home_page_payabel"
        style={{
          backgroundColor: headerColor,
        }}
      >
        {headerCont && headerCont.length ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4
              className="welcome_home_header"
              style={{ color: textColor ? textColor : "#ffffff" }}
              dangerouslySetInnerHTML={{
                __html: `${headerCont}`,
              }}
            />
            {industrySector == PrimaryandSecondaryEducation ? (
              <h4
                className="welcome_home_header"
                style={{ color: textColor ? textColor : "#ffffff" }}
              >
                Refer{" "}
                <span
                  style={{ fontSize: "20px", fontWeight: "400" }}
                  onClick={() => navigation("/FAQ")}
                >
                  FAQ
                </span>
              </h4>
            ) : (
              ""
            )}
          </div>
        ) : (
          <h4
            className="welcome_home_header"
            style={{ color: textColor ? textColor : "#ffffff" }}
          >
            If you are looking for Debt related Information, Kindly contact us
            at {email}
          </h4>
        )}
      </div>
      <div
        className="customize-ui-main-container"
        // onSubmit={}
      >
        <div className={"customize-ui-container"}>
          <div
            // className="customize-icon-header"
            style={{ padding: "60px 20px 5px", paddingBottom: "30px" }}
          >
            {orgLogo ? (
              <img
                src={orgLogo}
                style={{
                  width: "200px",
                  height: "180px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <Avatar
                src={orgLogo}
                size="100%"
                overrides={{
                  Root: {
                    style: () => ({
                      width: "150px",
                      height: "150px",
                      // background: "#000000",
                    }),
                  },
                  Initials: {
                    style: ({ $theme }) => ({
                      fontSize: "50px",
                    }),
                  },
                }}
                name={orgName}
              />
            )}
          </div>
          {!isState ? (
            <>
              <div
                className="sub-container-form"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "5px",
                  marginTop: "10px",
                }}
              >
                <div style={{ width: "300px" }}>
                  <Controller
                    name="value"
                    style={{ margin: "0" }}
                    control={control}
                    render={({ field }) => (
                      <TextBox
                        {...field}
                        shouldUnregister
                        fullWidth
                        size={"mini"}
                        requiredAstric={true}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={
                          labelEmailorPhone
                            ? labelEmailorPhone
                            : intl.formatMessage({
                                id: "enter_your_phone_or_email",
                              })
                        }
                        placeholder={
                          labelEmailorPhone
                            ? labelEmailorPhone
                            : intl.formatMessage({
                                id: "enter_your_phone_or_email",
                              })
                        }
                        value={field.value}
                      />
                    )}
                  />
                </div>

                {initialConfig && initialConfig.length > 0 && (
                  <LabelMedium
                    style={{
                      color: "#ADADAD",
                      marginBottom: "16px",
                      fontWeight: "400",
                    }}
                  >
                    ------------------------ or ------------------------
                  </LabelMedium>
                )}

                {initialConfig && initialConfig.length > 0 && (
                  <div style={{ width: "300px", margin: "0" }}>
                    <Controller
                      shouldUnregister
                      key={`${_.get(
                        initialConfig,
                        "[0].fm",
                        ""
                      ).toLowerCase()}_${initialConfig[0].fk}`}
                      control={control}
                      name={`${_.get(
                        initialConfig,
                        "[0].fm",
                        ""
                      ).toLowerCase()}_${initialConfig[0].fk}`}
                      render={({ field }) => {
                        return (
                          // <div
                          //   style={{
                          //     left: 0,
                          //     top: 0,
                          //     width: "250px",
                          //   }}
                          // >
                          <InputComponent
                            {...field}
                            dateFormat={dateFormat}
                            item={initialConfig[0]}
                            requiredAstric={true}
                            value={field.value}
                            rules={{ required: "Required" }}
                            onChange={(e) => {
                              const newValue =
                                initialConfig[0].ft === "DT"
                                  ? e.date
                                  : e.target.value;
                              field.onChange(newValue);
                            }}
                            label={initialConfig[0].lb}
                            error={_.get(errors, `${field.name}.message`)}
                          />
                          // </div>
                        );
                      }}
                    />
                  </div>
                )}
              </div>
              <TextButton
                type="submit"
                kind="primary"
                size="mini"
                style={{
                  marginTop: "10px",
                  width: "150px",
                }}
                disabled={requestTime.isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                Next
              </TextButton>
            </>
          ) : (
            <>
              <div
                className="sub-container-form"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "5px",
                  marginTop: "10px",
                }}
              >
                {isStoreAuth &&
                  Array.isArray(isStoreAuth.authorization) &&
                  isStoreAuth.authorization.map((item, index) => (
                    <div key={index} style={{ marginTop: "10px" }}>
                      <Controller
                        shouldUnregister
                        key={`${_.get(item, "fm", "").toLowerCase()}_${
                          item.fk
                        }`}
                        control={control}
                        name={`${_.get(item, "fm", "").toLowerCase()}_${
                          item.fk
                        }`}
                        render={({ field }) => {
                          return (
                            <div
                              style={{
                                left: 0,
                                top: 0,
                                width: "250px",
                              }}
                            >
                              <InputComponent
                                {...field}
                                item={item}
                                requiredAstric={true}
                                value={field.value}
                                rules={{ required: "Required" }}
                                onChange={(e) => {
                                  const newValue =
                                    item.ft === "DT" ? e.date : e.target.value;
                                  field.onChange(newValue);
                                }}
                                label={item.lb}
                                error={_.get(errors, `${field.name}.message`)}
                              />
                            </div>
                          );
                        }}
                      />
                    </div>
                  ))}
              </div>
              <TextButton
                type="submit"
                kind="primary"
                size="mini"
                style={{ marginTop: "10px", width: "150px" }}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </TextButton>
            </>
          )}

          {/* <div className="compliance-info-section">
            <LabelMedium
              style={{
                color: "#787878",
                fontSize: "11px",
                lineHeight: "15px",
                fontWeight: "400",
              }}
            >
              hello
            </LabelMedium>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default HomeDefault;
