import React from "react";
import { TextButton } from "../../components/TextButton";
import InputComponent from "../AuthorizeDownload/InputComponent";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import moment from "moment";
import { useMutation } from "react-query";
import { authChat, checkAuthorizsed } from "../../services/customerPortal";
import { toast } from "react-toastify";
import _ from "lodash";

const AuthForm = ({ isChatBot, setIsChatBot }) => {
  let document_authorize_data = useSelector(
    (e) => e.layoutReduceData.document_authorize_configurations
  );

  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const authorizeCall = useMutation((data) => authChat(data), {
    onError: (error, variables, context) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;
        toast.error(errorData);
      }
    },
    onSuccess: (data, variables, context) => {
      setIsChatBot({ type: "CHAT_BOT", data: data.data.doc });
      localStorage.setItem("AuthorizationToken", data.data.doc.token);
      toast.success(data.data.message);
    },
  });

  const onSubmit = async (data) => {
    const convertToTimestamp = (value) => {
      if (value instanceof Date) {
        return moment
          .tz(
            `${value ? value.getFullYear() : ""}-${
              value ? value.getMonth() + 1 : ""
            }-${value ? value.getDate() : ""}`,
            "YYYY-MM-DD",
            timeZone
          )
          .startOf("day")
          .utc()
          .valueOf();
      } else if (typeof value === "number") {
        return value.toString();
      }
      return value;
    };
    for (const key in data) {
      if (data[key] instanceof Date) {
        data[key] = convertToTimestamp(data[key]);
      } else if (typeof data[key] === "object" && !Array.isArray(data[key])) {
        for (const objKey in data[key]) {
          data[`${key}.${objKey}`] = convertToTimestamp(data[key][objKey]);
        }
        delete data[key];
      } else {
        data[key] = convertToTimestamp(data[key]);
      }
    }
    await authorizeCall.mutateAsync({ ...data });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="sub-container-form"
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "10px",
          padding: "15px",
        }}
      >
        {document_authorize_data.map((item) => (
          <Controller
            key={`${_.get(item, "fm", "").toLowerCase()}_${item.fk}`}
            control={control}
            name={`${_.get(item, "fm", "").toLowerCase()}_${item.fk}`}
            // rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <InputComponent
                  {...field}
                  item={item}
                  value={field.value}
                  onChange={(e) => {
                    const newValue = item.ft === "DT" ? e.date : e.target.value;
                    field.onChange(newValue);
                  }}
                  label={item.lb}
                  error={errors[field.name] && errors[field.name].message}
                />
              );
            }}
          />
        ))}

        <TextButton
          type="submit"
          kind="primary"
          size="mini"
          style={{ marginTop: "10px", width: "150px" }}
          isLoading={authorizeCall.isLoading}
          disabled={authorizeCall.isLoading}
        >
          Submit
        </TextButton>
      </form>
    </>
  );
};

export default AuthForm;
