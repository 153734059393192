import React, { useEffect, useState, useRef, useContext, useMemo } from "react";

// import { MxPluginContext } from "../providers/MxPluginProvider.js";
// import { motion } from "framer-motion";
// import { chatWithUs } from "../services/plugin.js";

import moment from "moment";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { chatWithUs } from "../../services/customerPortal";
import { toast } from "react-toastify";
import Icon from "../../components/Icon/Icon";

const BOT_NAME = "Zoya";
const USER = "You";

function LoadingBubble() {
  return (
    <div style={{ width: "180px" }}>
      <div class="chat-bubble">
        <p>{BOT_NAME} is Typing</p>
        <div class="typing">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </div>
  );
}

function TextBubble(props) {
  const TEXT_SPEED = 40;

  let { customer_portal_ui } = useSelector(
    (e) => e.layoutReduceData.organization
  );
  const { layoutData } = props;

  // const { customer_portal_ui } = layoutData;

  const { isBot = false, content = "", date, btnText = "", url } = props;
  const [response, setResponse] = useState("");

  useEffect(() => {
    if (isBot) {
      let index = 0;
      const interval = setInterval(() => {
        if (index < content.length) {
          setResponse((prev) => content.slice(0, index + 1));
          index++;
        } else {
          clearInterval(interval);
        }
      }, TEXT_SPEED);

      return () => clearInterval(interval);
    } else {
      setResponse(content);
    }
  }, []);

  return (
    <>
      <div className={`bubble-container ${isBot ? "bot" : "user"}`}>
        <div
          className={`chat-bubble ${isBot ? "bot" : "user"}`}
          style={
            isBot
              ? {}
              : {
                  background: customer_portal_ui?.primary_color,
                  color: "#ffffff",
                }
          }
        >
          <div
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 1 }}
          // transition={{ duration: 1 }}
          >
            <span style={{ whiteSpace: "break-spaces" }}>{content}</span>
          </div>
        </div>
      </div>
      {url && (
        <div
          className="chat-button"
          onClick={() => window.open(`${url}`, "_blank")}
        >
          <p>{`${btnText ? btnText : "Click Here"}`}</p>
        </div>
      )}
      <p className={`bubble-info ${isBot ? "bot" : "user"}`}>
        {isBot ? BOT_NAME : USER}{" "}
        {date && String(date).length
          ? `- ${moment.utc(date).local().format("hh:mm A")}`
          : ""}
      </p>
    </>
  );
}

function SupportWindow(props) {
  const textField = useRef(null);

  const chatContainerRef = useRef(null);

  const { isChatBot } = props;

  let { customer_portal_ui } = useSelector(
    (e) => e.layoutReduceData.organization
  );

  const [history, setHistory] = useState([...props?.history]);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  console.log("HISTORY__DATA", props);

  const [isDisable, setIsDisable] = useState(false);

  const addHistory = (content, isBot = false, data = {}) => {
    setText("");
    setHistory((history) => [
      ...history,
      {
        index: history.length + 1,
        isBot,
        content,
        date: moment().utc().valueOf(),
        ...data,
      },
    ]);
    scrollToBottom();
  };

  useEffect(() => {
    scrollToBottom();
  }, [history?.length]);

  const authorizeCall = useMutation((data) => chatWithUs(data), {
    onError: (error, variables, context) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;
        toast.error(errorData);
      }
    },
    onSuccess: (data, variables, context) => {
      // const maxDelay = 3000; //3s
      // const time = new Date().valueOf();
      // const diff = time - new Date().valueOf()

      if (data?.data?.doc?.response) {
        addHistory(data?.data?.doc?.response, true, data?.data?.doc);
      }
      toast.success(data.data.message);
    },
  });

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const onSubmit = async () => {
    if (text.trim().length === 0) {
      return null;
    }

    scrollToBottom();
    addHistory(text);
    setLoading(true);

    const value = {
      content: text,
      session_id: isChatBot.data?.session_id,
    };

    await authorizeCall.mutateAsync({ ...value });

    scrollToBottom();
    setLoading(false);
  };

  return (
    <div className="supportWindow">
      <div className="chatContainer" ref={chatContainerRef}>
        {history.map((e, i) => (
          <TextBubble {...e} />
        ))}
        {loading || history.length === 0 ? <LoadingBubble /> : <></>}
      </div>

      <div className="formContainer">
        <form>
          <div className="inputContainer">
            <textarea
              ref={textField}
              disabled={isDisable}
              value={text}
              autofocus
              onChange={(e) => setText(e.target.value)}
              placeholder="Enter your message here..."
              className="inputText"
              autoFocus
              onKeyDown={async (e) => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  onSubmit();
                }
              }}
            />
            <button
              type="button"
              disabled={isDisable}
              className="sendButton"
              style={{ backgroundColor: customer_portal_ui?.primary_color }}
              onClick={async () => {
                onSubmit();
              }}
            >
              <Icon icon="send" color={"#ffffff"} />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const SupportWindowDataLayer = (props) => {
  console.log("props>>>>>", props);
  const history = useMemo(() => {
    if (props?.isChatBot?.data?.history?.length) {
      let historyData = props?.isChatBot?.data?.history.map((e) => {
        return {
          ...e,
          isBot: e.role === "a" ? true : false,
        };
      });
      console.log("historyData", historyData);
      return historyData;
      // [...props?.userData?.history];
    }
    return [
      {
        index: 1,
        isBot: true,
        content: props?.isChatBot?.data?.response,
        date: moment().utc().valueOf(),
      },
    ];
  }, []);

  console.log("HISTORY__DATA", history);

  return (
    <SupportWindow
      history={history && Array.isArray(history) ? history : []}
      {...props}
    />
  );
};

export default SupportWindowDataLayer;
