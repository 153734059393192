import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  postPrePaymentPlan,
  createAutoDebitPaymentPlan,
} from "../../services/customerPortal";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { ParagraphMedium, LabelLarge, ParagraphLarge } from "baseui/typography";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { KIND, SIZE } from "baseui/button";
import { TextButton } from "../../components/TextButton";
import startdate from "../../assets/img/startdate.png";
import Right from "../../assets/img/svg/Right";
import { IconButton } from "../../components/IconButton";
import CalendarSvg from "../../assets/img/svg/CalendarSvg";
import _ from "lodash";
import Info from "../../assets/img/svg/Info";
import ConfirmationModel from "../../components/ConfirmationModel/ConfirmationModel";
import { SelectBox } from "../../components/SelectBox/SelectBox";

const DateChoose = ({
  setIsSelect,
  iscount,
  value,
  setValues,
  data,
  is_allow_auto_debit_plan = false,
  auto_debit_payment_gateways = [],
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmMessage, setIsConfirmMessage] = useState({});
  const [isPaymentType, setIsPaymentType] = useState([{ id: "CARD" }]);
  const { hash } = useParams();
  const navigator = useNavigate();
  const [selectCard, setSelectCard] = useState(true);
  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );

  const { is_ach_enabled = false } = data;

  const { customer, organization, referenceData } = useSelector(
    (s) => s.layoutReduceData
  );
  let requestDataValied = false;
  let layoutData = useSelector((s) => s.layoutReduceData);
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const openModal = (confirmMessage, paymentPlanData) => {
    setIsOpen(true);
    setIsConfirmMessage({
      layoutData: confirmMessage,
      paymentPlanData: paymentPlanData,
    });
  };

  const [isdate, setIsDate] = useState();

  const postTransfer = useMutation(
    (data) =>
      is_allow_auto_debit_plan
        ? createAutoDebitPaymentPlan(data)
        : postPrePaymentPlan(data),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        if (data && data.data && data.data.redirect_url) {
          setIsOpen(false);
          window.location.replace(data.data.redirect_url);
        }

        toast.success(data.data.message);
        setIsOpen(false);
        // navigator(`/${hash}`);
        setSelectCard(false);
        setIsDate(data);
      },
    }
  );
  let { start_date } = watch();
  const onSubmit = async (data) => {
    let { start_date } = data;
    let value = {
      ...(is_ach_enabled
        ? {
            payment_type:
              isPaymentType && isPaymentType[0] && isPaymentType[0].id,
          }
        : {}),
      start_date: moment
        .tz(
          `${start_date.getFullYear()}-${
            start_date.getMonth() + 1
          }-${start_date.getDate()}`,
          "YYYY-MM-DD",
          timeZone
        )
        .startOf("day")
        .utc()
        .valueOf(),
      ...(is_allow_auto_debit_plan ? { is_predefined_plan: true } : {}),
      pre_defined_plan_id: iscount.id,
      payment_plan_info: auto_debit_payment_gateways?.[0]?.payment_plan_info,
    };
    if (
      (_.get(layoutData, "customer.isEnableCPConfirmation", false) &&
        _.get(layoutData, "customer.cpConfirmationTypes", [])?.includes(
          "PTP"
        )) ||
      auto_debit_payment_gateways?.[0]?.payment_plan_info?.length
    ) {
      openModal(
        _.get(layoutData, "customer.cpConfirmationMessages", []),
        value
      );
    } else {
      await postTransfer.mutateAsync(value);
    }
  };

  const { default_color } = useSelector((s) => s.layoutReduceData);

  return (
    <>
      <div className="invoices-container">
        <ConfirmationModel
          postTransfer={postTransfer}
          isOpen={isOpen}
          modelName={"PAYMENT_PLAN_DATE"}
          setIsOpen={setIsOpen}
          isConfirmMessage={isConfirmMessage}
          setIsConfirmMessage={setIsConfirmMessage}
        />

        <div>
          {selectCard ? (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="rq__date__form__wraper"
            >
              <div className="datechoose-title">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    justifyContent: "center",
                  }}
                >
                  {/* BACK BUTTON STARTS */}
                  <div className="datechoose_back_btn">
                    <div
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                        border: "0.5px solid #cdced9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => setValues("payment_plan_options")}
                    >
                      <i className="mx-icon-Group-16969" />
                    </div>
                  </div>
                  {/* BACK BUTTON ENDS */}

                  <div>
                    <LabelLarge
                      $style={{
                        textAlign: "center",
                      }}
                      // className="org_name"
                      className="payment-list-org_name"
                    >
                      <LabelLarge
                        $style={{
                          textAlign: "center",
                          color: "#333860",
                        }}
                      >
                        Select your payment plan start date
                      </LabelLarge>
                    </LabelLarge>
                  </div>

                  {/*  */}
                </div>
              </div>
              <div className="data_selecter">
                <div className="date-selecter_img">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "15px",
                    }}
                  >
                    {/* <img src={startdate} /> */}
                    <CalendarSvg
                      st0={default_color}
                      st5={default_color}
                      st4={default_color}
                    />
                  </div>
                  {!isOpen && (
                    <div className="req-time__input-wrap">
                      <div style={{ flex: "1" }}>
                        <Controller
                          name="start_date"
                          control={control}
                          rules={{ required: "Required" }}
                          render={({ field }) => (
                            <DateRangeSelect
                              {...field}
                              name={field.name}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              requiredAstric={true}
                              size={SIZE.mini}
                              label={intl.formatMessage({
                                id: "start_date",
                              })}
                              value={field.value}
                              onChange={(e) => field.onChange(e.date)}
                              inputoverrides={{
                                onKeyDown: (e) => e.preventDefault(),
                                inputMode: "none",
                              }}
                              minDate={
                                (auto_debit_payment_gateways &&
                                  auto_debit_payment_gateways[0] &&
                                  auto_debit_payment_gateways[0]
                                    .gateway_name === "ACCEPTBLUE") ||
                                auto_debit_payment_gateways?.[0]
                                  ?.gateway_name === "REPAY_CHANNEL"
                                  ? moment().add(1, "day").toDate()
                                  : new Date()
                              }
                              maxDate={
                                (auto_debit_payment_gateways &&
                                  auto_debit_payment_gateways[0] &&
                                  auto_debit_payment_gateways[0]
                                    .gateway_name === "ACCEPTBLUE") ||
                                auto_debit_payment_gateways?.[0]
                                  ?.gateway_name === "REPAY_CHANNEL"
                                  ? moment().add(iscount.days, "day").toDate()
                                  : moment()
                                      .add(iscount.days - 1, "day")
                                      .toDate()
                              }
                            />
                          )}
                        />
                      </div>
                      {/* className="payment_type" */}
                      {data && data?.is_ach_enabled && (
                        <div style={{ flex: "1" }}>
                          <SelectBox
                            size="mini"
                            value={isPaymentType}
                            backspaceRemoves={false}
                            searchable={false}
                            clearable={false}
                            options={referenceData["online_payment_type"]}
                            readonly="readonly"
                            label={"Payment Type"}
                            onChange={(params) =>
                              setIsPaymentType(params.value)
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}

                  <div className="mobile-submir-date">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <TextButton
                          fullWidth
                          kind={KIND.primary}
                          size={SIZE.compact}
                          type="submit"
                          isLoading={isSubmitting}
                          disabled={
                            requestDataValied || isSubmitting || !start_date
                          }
                        >
                          Submit
                        </TextButton>
                      </div>
                    </div>
                  </div>

                  {is_allow_auto_debit_plan ? (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <div style={{ paddingTop: "3px" }}>
                        <Info />
                      </div>
                      <ParagraphMedium
                        $style={{
                          color: "#ADADAD",
                          lineHeight: "16px",
                          textAlign: "justify",
                        }}
                      >
                        Simplify your payment experience with auto-debit.
                        Securely provide your payment information to ensure
                        seamless and automated installment processing. A
                        processing fee, if applicable, will be shown on the next
                        page.
                      </ParagraphMedium>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {/* <div> */}
              {/* Footer Add Start  */}
              <div
                className="tabviewpayment"
                style={{
                  bottom: " 0 %",
                  width: "100 %",
                  borderRadius: "5px",
                  borderTop: " 1px solid #CDCED9",
                  width: "100%",
                  background: default_color,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: "10px",
                  padding: "10px",
                }}
              >
                {/* <div style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}> */}

                <div
                  style={{
                    // width: "100px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <TextButton
                    fullWidth
                    kind={KIND.primary}
                    size={SIZE.mini}
                    type="button"
                    isLoading={isSubmitting}
                    disabled={requestDataValied || isSubmitting}
                    onClick={() => {
                      setValue("start_date", "");
                    }}
                    $style={{
                      boxShadow: "none",
                      ":hover": {
                        boxShadow: "inset 0px 4px 4px 0px #00000025",
                        borderRadius: "5px",
                        backgroundColor: default_color,
                      },
                    }}
                  >
                    Reset
                  </TextButton>
                </div>
                <div>
                  <TextButton
                    fullWidth
                    kind={KIND.secondary}
                    size={SIZE.mini}
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={requestDataValied || isSubmitting || !start_date}
                  >
                    {is_allow_auto_debit_plan ? "Next" : "Submit"}
                  </TextButton>
                </div>
              </div>

              {/* </div > */}
              {/* Footer Add End  */}
            </form>
          ) : (
            <>
              <div
                className="rq__call__form__wraper"
                style={{ paddingTop: "5px", width: "100%" }}
              >
                <div
                  className="req__show_d"
                  style={{ flexDirection: "column", gap: "10px" }}
                >
                  <div className="rpt-svg-header">
                    <LabelLarge
                      $style={{
                        textAlign: "center",
                        color: "#333860",
                      }}
                    >
                      Payment plan successfully submitted
                    </LabelLarge>
                  </div>

                  <div>
                    <Right />
                  </div>
                  <div>
                    <div>
                      <div className="rpt_val">
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            paddingBottom: "20px",
                          }}
                        >
                          <ParagraphMedium style={{ whiteSpace: "noWrap" }}>
                            Payment plan starts from{" "}
                            {moment
                              .tz(start_date, timeZone)
                              .format(organization.date_format)}
                          </ParagraphMedium>
                        </div>

                        <NavLink to={`/${hash}`}>
                          <TextButton
                            fullWidth
                            kind={KIND.secondary}
                            size={SIZE.compact}
                            startEnhancer={
                              <i className="mx-icon-Group-16179 font-icon-color" />
                            }
                          >
                            Go Back
                          </TextButton>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default DateChoose;
