import { Avatar } from "baseui/avatar";
import { HeadingXLarge } from "baseui/typography";
import React, { useState } from "react";
import Cancel from "../../assets/img/svg/Cancel";
import MenuNav from "../../assets/img/svg/MenuNav";

const NavBarHome = ({
  headerColor,
  orgLogo,
  orgName,
  textColor,
  websiteUrl,
  filteredHeaderArray,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <nav
      className="navbar-home"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 20px",
        height: "75px",
        backgroundColor: headerColor,
        color: "white",
      }}
    >
      <div
        className="lp-header-logo-container"
        style={{
          backgroundColor: headerColor,
          display: "flex",
          alignItems: "center",
          minWidth: "550px",
          gap: "10px",
          cursor: "pointer",
        }}
        onClick={() => window.open(websiteUrl)}
      >
        <Avatar
          src={orgLogo}
          size="100%"
          overrides={{
            Root: {
              style: () => ({
                width: "35px",
                height: "35px",
                backgroundColor: textColor,
                //   marginTop: "4px",
              }),
            },
            Initials: {
              style: ({ $theme }) => ({
                fontSize: "40px",
              }),
            },
            Avatar: {
              style: ({ $theme }) => ({
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
              }),
            },
          }}
          name={orgName}
        />
        <HeadingXLarge $style={{ color: textColor ? textColor : "#ffffff" }}>
          {orgName}
        </HeadingXLarge>
      </div>
      <ul
        style={{
          backgroundColor: headerColor,
          color: textColor ? textColor : "#ffffff",
        }}
        className={isMenuOpen ? "nav-links-home-mobile" : "nav-links-home"}
        onClick={() => {
          setIsMenuOpen(false);
        }}
      >
        {filteredHeaderArray
          .sort((a, b) => parseInt(a.position) - parseInt(b.position))
          .map((tab) => (
            <a
              key={tab.pos}
              href={tab.url}
              target={!tab.tar ? "_self" : "_blank"}
              rel="noopener noreferrer"
              className="nav-linkss"
              style={{ color: textColor ? textColor : "#ffffff" }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "rgba(255, 255, 255, 0.1)";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "transparent";
              }}
              onClick={(e) => {
                if (tab.nam === "FAQ") {
                  e.preventDefault(); // Prevent default anchor behavior
                  document.getElementById("faq-section")?.scrollIntoView({
                    behavior: "smooth",
                  });
                }
              }}
            >
              {tab.nam}
            </a>
          ))}
      </ul>
      <div
        className="mobile-view-menu-icon"
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        {isMenuOpen ? <Cancel color="white" /> : <MenuNav color="white" />}
      </div>
    </nav>
  );
};

export default NavBarHome;
