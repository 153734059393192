import React, { forwardRef } from "react";
import PaperPlane from "../../assets/img/svg/PaperPlane";

const Icon = forwardRef(function Icon(props, ref) {
  const {
    icon,
    color = "#333860",
    size = 22,
    style = {},
    className = "",
    isPressable = false,
    ...rest
  } = props;

  const IconSvg = iconRegistry[icon];

  return (
    <div
      ref={ref}
      style={{
        ...(style && typeof style === "object" ? style : {}),
      }}
      className={`mx-icon ${className} ${isPressable ? "mx-icon-button" : ""}`}
      {...rest}
    >
      {IconSvg ? (
        <IconSvg size={size} color={color} />
      ) : (
        <div
          style={{
            width: size,
            height: size,
            backgroundColor: color,
            overflow: "hidden",
          }}
        >
          {icon}
        </div>
      )}
    </div>
  );
});

const iconRegistry = {
  send: (props) => <PaperPlane {...props} />,
};

export default Icon;
