import React from "react";
import { IconButton, KIND } from "../IconButton";
import { emailSubText } from "../../services/customerPortal";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import _ from "lodash";
import EmailSvg from "../../assets/img/svg/emailSvg";
import { LabelMedium } from "baseui/typography";
import { useSelector } from "react-redux";

const EmailUs = ({ cusData, organizationData, type }) => {
  const { default_color } = useSelector((s) => s.layoutReduceData);
  const emailSubjectMut = useMutation((data) => emailSubText(), {
    onError: (error) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;
        toast.error(errorData);
      }
    },
    onSuccess: (data) => {
      window.location.assign(
        `mailto:${_.get(
          cusData,
          "em_conf",
          organizationData?.email
        )}?subject=${_.get(data, "data.doc.em_sub", "")}`
      );
      // toast.success(data.data.message);
    },
  });

  switch (type) {
    case "SIMPLE":
      return (
        <div className="action_wraper">
          <div
            className="icon-border"
            onClick={() =>
              !emailSubjectMut.isLoading && emailSubjectMut.mutateAsync()
            }
          >
            <EmailSvg size={18} color={default_color} />
          </div>
          <LabelMedium
            type="p"
            style={{
              fontWeight: "500",
              fontSize: "13px",
              lineHeight: "17px",
            }}
          >
            Email us
          </LabelMedium>
        </div>
      );
    default:
      return (
        <div>
          <div
            class="button-tilt email-btn"
            onClick={() =>
              !emailSubjectMut.isLoading && emailSubjectMut.mutateAsync()
            }
          >
            <IconButton
              kind={KIND.tertiary}
              disabled={emailSubjectMut.isLoading}
              isLoading={emailSubjectMut.isLoading}
            >
              <i className="mx-icon-Vector-2" />
              <span class="label-hidden">Email us</span>
            </IconButton>
          </div>
        </div>
      );
  }
};

export default EmailUs;
