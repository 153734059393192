import React from "react";

const HelpSvg = ({ size = "48", color = "#5f6368" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 -960 960 960"
      width={size}
      fill={color}
    >
      <path d="M483.97-262.92q12.65 0 21.68-9.4 9.04-9.39 9.04-22.04 0-11.87-9.01-21.29-9.01-9.43-21.65-9.43-12.65 0-22.07 9.4t-9.42 21.27q0 12.64 9.39 22.06 9.4 9.43 22.04 9.43ZM457.62-399h44.07q.77-25.25 8.31-44.24 7.54-18.99 36.08-44.61 28.69-25.77 41.42-48.61t12.73-51.31q0-49.34-32.54-79.05-32.54-29.72-81.99-29.72-45.24 0-78.2 23.04t-48.42 57.73l40.69 16.31q9.77-24.69 29.35-40.54 19.57-15.85 53.11-15.85 37.39 0 57.62 20.58 20.23 20.58 20.23 49.35 0 21.23-12.18 38.8-12.17 17.58-32.9 36.27-31.46 28-44.42 53.62-12.96 25.61-12.96 58.23Zm22.06 295q-77.63 0-145.82-29.86t-119.14-80.76q-50.95-50.9-80.83-119.16Q104-402.05 104-479.87q0-77.94 29.92-146.55 29.92-68.62 81.11-119.84 51.18-51.22 119.08-80.48Q402.01-856 479.42-856q78 0 146.92 29.23 68.92 29.24 119.93 80.43 51 51.18 80.36 119.9Q856-557.72 856-479.59q0 77.9-29.23 145.59-29.24 67.69-80.41 118.9-51.18 51.21-119.88 81.16Q557.79-104 479.68-104Zm.32-43.77q137.9 0 235.06-97.28 97.17-97.28 97.17-234.95 0-137.9-97.17-235.06Q617.9-812.23 480-812.23q-137.67 0-234.95 97.17Q147.77-617.9 147.77-480q0 137.67 97.28 234.95T480-147.77ZM480-480Z" />
    </svg>
  );
};

export default HelpSvg;
