import * as React from "react";
import { DatePicker } from "baseui/datepicker";
import { FormControl } from "baseui/form-control";
import { useSelector } from "react-redux";
import CaldendarIconSvg from "../../assets/img/svg/CaldendarIconSvg";
import { useRef } from "react";
import _ from "lodash";
// Date Calculation
const wtd = (x = 0) => {
  var d = new Date(); // today!
  return new Date(d.setDate(d.getDate() - x));
};
const td = (x = 0) => {
  var m = new Date();
  return new Date(m.setMonth(m.getMonth() - x));
};
const DatePickerDownload = (props) => {
  const datepickerRef = useRef(null); // OR React.createRef();  if you are not using hooks
  // OPENS UP THE DATEPICKER WHEN THE CALENDAR ICON IS CLICKED FOR THE INPUT FIELD
  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current;
    if (datepickerElement) {
      datepickerElement.open();
    }
  }

  let {
    error = "",
    value = "",
    label = "",
    requiredAstric = false,
    borderRadiusOverride,
  } = props;

  const dateFormatReducers = useSelector((e) => e.layoutReduceData?.dateFormat);

  let dateFormat = dateFormatReducers?.replace(/DD|YYYY/g, (match) =>
    match.toLowerCase()
  );

  console.log(props, "LLLLLLLl");

  const [isFocused, setFocused] = React.useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  return (
    <>
      <FormControl
        error={error}
        overrides={{
          Caption: {
            style: ({ $theme }) => ({
              marginTop: "0px",
              marginBottom: "0px",
              marginLeft: "12px",
              textAlign: "left",
            }),
          },
        }}
      >
        <div className="mx-input">
          <label
            htmlFor={props.name}
            className={`mx-input__label   ${
              isFocused ? "mx-input__label--active text-primary" : ""
            } ${value ? "mx-input__label--active" : ""} ${
              error ? "text-error" : ""
            }`}
          >
            {label}{" "}
            {requiredAstric ? (
              <>
                <span style={{ color: "red" }}>*</span>
              </>
            ) : (
              <></>
            )}
          </label>

          <DatePicker
            placeholder=" "
            {...props}
            id={props.name}
            autoComplete="off"
            autoFill="off"
            value={value}
            ref={datepickerRef}
            minDate={new Date("1900-01-01T00:00:00.000Z")}
            overrides={{
              Root: {
                style: () => ({
                  zIndex: "7",
                }),
              },
              CalendarContainer: {
                style: ({ $theme }) => ({
                  zIndex: "7",
                }),
              },
              CalendarHeader: {
                style: ({ $theme }) => ({
                  zIndex: "7",
                }),
              },
              Input: {
                props: {
                  id: props.name,
                  overrides: {
                    Root: {
                      style: () => ({
                        borderBottomRightRadius: "5px",
                        borderBottomLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        borderTopLeftRadius: "5px",
                        border: "0.5px solid #CDCDD9",
                        backgroundColor: "#fffff",
                        borderRadius: borderRadiusOverride,
                      }),
                    },
                    Input: {
                      style: () => ({
                        fontSize: "13px",
                        color: props?.textColor,
                      }),
                    },
                    InputContainer: {
                      style: () => ({
                        backgroundColor: "#fffff",
                      }),
                    },
                  },
                },
              },
            }}
            onFocus={() => {
              if (props.onFocus) {
                props.onFocus();
              }
              onFocus();
            }}
            onBlur={() => {
              if (props.onBlur) {
                props.onBlur();
              }
              onBlur();
            }}
            maxDate={new Date()}
            formatString={dateFormat}
          />
          <div
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={() => handleClickDatepickerIcon()}
          >
            <CaldendarIconSvg />
          </div>
        </div>
      </FormControl>
    </>
  );
};

export default DatePickerDownload;
