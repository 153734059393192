import React, { useState } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import moment from "moment";
import { useMutation } from "react-query";
import { getConfigField, getHomePage } from "../../services/customerPortal";
import { toast } from "react-toastify";
import CustomFormUtill from "./CustomFormUtill";
const CustomFormContainer = ({
  initialConfig,
  timeZone,
  orgId,
  alignment,
  labelEmailorPhone,
  term_privacy,
  privacy,
  rightsConfigContent,
  textColor,
}) => {
  const [isState, setState] = useState("initial");
  const [isStoreAuth, setIsStoreAuth] = useState({});
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    // defaultValues: { authorization: [] },
  });

  const requestTime = useMutation((data) => getHomePage(data), {
    onError: (error, variables, context) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;
        toast.error("We couldn't find any matching records");
      }
    },
    onSuccess: (data, variables, context) => {
      reset();
      if (data && data.data) {
        setIsStoreAuth(data && data.data);
        setState("auth");
      }
    },
  });

  const finalAuth = useMutation((data) => getConfigField(data), {
    onError: (error, variables, context) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;

        toast.error("We couldn't find any matching records");

        setState(false);
        setIsStoreAuth({});
        reset();
      }
    },
    onSuccess: (data, variables, context) => {
      reset();
      toast.success(data.data.message);
      window.open(data?.data?.url, "_self");
    },
  });

  const onSubmitFormOne = async (items) => {
    console.log(items, "FormOne Submission");

    const keyCus = `${_.get(initialConfig, "[0].fm", "").toLowerCase()}_${
      initialConfig?.[0]?.fk
    }`;

    let fieldData = {
      phone: undefined,
      email: undefined,
    };

    const phonePattern = /^(\d{3}\s\d{3}\s\d{4}|\d{5}\s\d{5}|\d{10})$/;
    const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

    if (phonePattern.test(items?.value)) {
      fieldData.phone = _.get(items, "value", "").replace(/\s/g, "");
      clearErrors("value");
    } else if (emailPattern.test(items?.value)) {
      fieldData.email = items.value;
      clearErrors("value");
    }

    if (isState !== "auth" && !items.value && !items[keyCus]) {
      setError("value", {
        type: "manual",
        message: "Either phone or email is required",
      });
      setError(`${keyCus}`, {
        type: "manual",
        message: `${initialConfig[0].lb} required`,
      });
      return;
    } else {
      clearErrors("value");
      clearErrors("email");
    }
    let { email, phone, ...rest } = items;

    const keys = Object.keys(rest);
    let isError = false;

    if (
      rest &&
      keys.length > 0 &&
      isStoreAuth &&
      Array.isArray(isStoreAuth.authorization)
    ) {
      for (let item of isStoreAuth.authorization) {
        const key = `${_.get(item, "fm", "").toLowerCase()}_${item.fk}`;
        const value = _.get(rest, key);

        if (!value) {
          setError(key, {
            type: "manual",
            message: "Required",
          });
          isError = true;
        } else {
          clearErrors(key);
        }
      }
    }

    if (isError) {
      return null;
    }

    const convertToTimestamp = (value) => {
      if (value instanceof Date) {
        return moment
          .tz(
            `${value ? value.getFullYear() : ""}-${
              value ? value.getMonth() + 1 : ""
            }-${value ? value.getDate() : ""}`,
            "YYYY-MM-DD",
            timeZone
          )
          .startOf("day")
          .utc()
          .valueOf();
      } else if (typeof value === "number") {
        return value.toString();
      }
      return value;
    };

    let dataInital = {
      search_val: {
        ...(items[keyCus] instanceof Date
          ? {
              [keyCus]: convertToTimestamp(items[keyCus]),
            }
          : { [keyCus]: items[keyCus] }),
      },
    };

    if (isState !== "auth") {
      let values = {
        ...fieldData,
        ...(dataInital && dataInital.search_val && dataInital.search_val[keyCus]
          ? { search_val: { [keyCus]: dataInital.search_val[keyCus] } }
          : {}),
        organizationId: orgId,
        queryParam: "CP_ORG_REFERER",
      };
      await requestTime.mutateAsync(values);
    } else if (isState === "auth") {
      for (const key in rest) {
        if (rest[key] instanceof Date) {
          rest[key] = convertToTimestamp(rest[key]);
        } else if (typeof rest[key] === "object" && !Array.isArray(rest[key])) {
          for (const objKey in rest[key]) {
            rest[`${key}.${objKey}`] = convertToTimestamp(rest[key][objKey]);
          }
          delete rest[key];
        } else {
          rest[key] = convertToTimestamp(rest[key]);
        }
      }

      let { cus_custom_field, ...customField } = rest;
      Object.keys(customField).forEach((key) => {
        if (key.toLowerCase().includes("phone")) {
          // Check if the key contains "phone"
          customField[key] = customField[key]?.replace(/\s/g, ""); // Remove spaces
        }
      });

      const dataToSubmit = {
        ...customField,
        token: isStoreAuth && isStoreAuth.accessToken,
      };
      console.log(dataToSubmit, cus_custom_field, "dataToSubmit");

      await finalAuth.mutateAsync(dataToSubmit);
    }
  };

  console.log(isState, isStoreAuth, "isState");

  switch (isState) {
    case "auth":
      return (
        <CustomFormUtill
          // phoneEmail
          // termsCondition
          buttonName={"Submit"}
          stateType={isState}
          customError={errors}
          customControl={control}
          // requestLoading
          configMapping={{
            type: "auth",
            data: _.get(isStoreAuth, "authorization", []),
          }}
          buttonSubmit={handleSubmit(onSubmitFormOne)}
        />
      );

    default:
      return (
        <CustomFormUtill
          phoneEmail={{
            label: labelEmailorPhone,
            placeHolder: labelEmailorPhone,
          }}
          // alignment={alignment}
          textColor={textColor}
          termsCondition={term_privacy}
          privacy={privacy}
          rightsConfigContent={rightsConfigContent}
          buttonName={"Next"}
          stateType={isState}
          customError={errors}
          customControl={control}
          watchControl={watch}
          requestLoading={requestTime?.isLoading}
          configMapping={{
            type: "initial",
            data: initialConfig || [],
          }}
          buttonSubmit={handleSubmit(onSubmitFormOne)}
        />
      ); // Optionally return a default case
  }
};

export default CustomFormContainer;
