import React from "react";

const ReviewRecipt = ({ size = "48", color = "#5f6368" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 -960 960 960"
      width={size}
      fill={color}
    >
      <path d="M232-120q-30.67 0-52.07-21.39-21.39-21.4-21.39-51.71v-82.28h123.15v-549.24l50.73 35.39 52.26-35.39 52.26 35.39 52.26-35.39 52.26 35.39 52.31-35.39 52.31 35.39 52.3-35.39 52.31 35.39 50.77-35.39v631.16q0 30.67-21.39 52.07Q758.67-120 728-120H232Zm496.38-30.77q18.08 0 30.2-12.11 12.11-12.12 12.11-30.58v-575.77H312.46v493.85h373.62v81.92q0 18.46 12.11 30.58 12.12 12.11 30.19 12.11ZM373.92-630.46v-30.77h213.85v30.77H373.92Zm0 127.08v-30.77h213.85v30.77H373.92ZM680-619.69q-10.46 0-18.31-7.85-7.84-7.84-7.84-18.31 0-10.46 7.84-18.3Q669.54-672 680-672q10.46 0 18.31 7.85 7.84 7.84 7.84 18.3 0 10.47-7.84 18.31-7.85 7.85-18.31 7.85Zm0 124.38q-10.46 0-18.31-7.84-7.84-7.85-7.84-18.31 0-10.46 7.84-18.31 7.85-7.85 18.31-7.85 10.46 0 18.31 7.85 7.84 7.85 7.84 18.31 0 10.46-7.84 18.31-7.85 7.84-18.31 7.84ZM231.22-150.77h424.09v-93.85h-466v51.16q0 18.46 12.26 30.58 12.27 12.11 29.65 12.11Zm-41.91 0v-93.85 93.85Z" />
    </svg>
  );
};

export default ReviewRecipt;
