import {
  HeadingXLarge,
  HeadingXSmall,
  LabelMedium,
  ParagraphMedium,
  LabelSmall,
  LabelLarge,
  ParagraphSmall,
} from "baseui/typography";
import React, { useState, useMemo, useEffect, useContext } from "react";
import { KIND } from "baseui/button";
import { IconButton } from "../../components/IconButton";
import MOCK_DATA from "../Invoices/MOCK_DATA.json";
import { COLUMNS } from "../Invoices/columns";
import TextButton from "../../components/TextButton/TextButton";
import { SIZE } from "baseui/button";
import CircleTick from "../../assets/img/svg/CircleTick";
import { TextArea } from "../../components/TextArea/TextArea";
import { TextBox } from "../../components/TextBox/TextBox";
import { useIntl } from "react-intl";
import MessageHeaderSvg from "../../assets/img/svg/MessageHeaderSvg";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import _ from "lodash";
import {
  getCallBackLater,
  getPromiseToPay,
} from "../../services/customerPortal";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import "moment-timezone";
import { type } from "@testing-library/user-event/dist/type";
import { utcTimstampToLocalDate } from "../../utils/utcTimstampToLocalDate";
import setSelectValues from "../../utils/setSelectValues";
import { paymentAmount, refetachLayoutData } from "../../redux/Layout/action";
import reqTime from "../../assets/img/req-time.png";
import LeftArrow from "../../assets/img/svg/LeftArrow";
import Info from "../../assets/img/svg/Info";
import { NumericFormat } from "react-number-format";
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import PromiseToPaySvg from "../../assets/img/svg/PromiseToPaySvg";
import { Avatar } from "baseui/avatar";
import PaymentPlan from "../PaymentPlan/PaymentPlan";
import PaymentAmount from "../PaymentAmount/PaymentAmount";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import DebtorRight from "../../assets/img/svg/DebtorRight";
import { setDebtorRightsModal } from "../../redux/Layout/action";
import EmailUs from "../../components/EmailUs/EmailUs";

const RequestingTime = () => {
  const intl = useIntl();
  const location = useLocation();
  const hash = location.pathname.split("/")[1];
  const [count, setCount] = useState(0);
  const { data, isLoading } = useQuery(
    [`PROMISE-TO-PAY-${count} `],
    async () => {
      return await getCallBackLater({ entity: "PROMISE_TO_PAY" });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  let dispatch = useDispatch();

  let organizationData = useSelector((e) => e.layoutReduceData.organization);

  // const hash = location.pathname.split("/")[1];

  const [selectCard, setSelectCard] = useState(true);

  let customerData = useSelector((e) => e.layoutReduceData?.customer);
  let accessValue = useSelector((e) => e.layoutReduceData.accessData);

  let requestTimeData = useSelector((e) => e.layoutReduceData?.requestBack);
  let requestTimeDataList = useSelector(
    (e) => e.layoutReduceData?.requestTimeData
  );
  let orgData = useSelector((e) => e.layoutReduceData?.organization);

  let navigate = useNavigate();

  const { default_color } = useSelector((s) => s.layoutReduceData);

  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );

  let requestDataValied = false;

  if (data?.data?.doc.length == 0) {
    requestDataValied = true;
  }
  const customizedData = useSelector((state) => state?.customizeReducer?.data);
  function sendEmail() {
    if (_.get(customizedData, "em_conf", "")) {
      window.location.assign(`mailto:${_.get(customizedData, "em_conf", "")}`);
    } else {
      window.location.assign(
        `mailto:${organizationData && organizationData.email}`
      );
    }
  }
  const currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  const {
    customer: { total_outstanding_amount = 0, currency = "" },
  } = useSelector((s) => s.layoutReduceData);

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  let dateFormat = useSelector(
    (e) =>
      e && e.layoutReduceData && e.layoutReduceData?.organization.date_format
  );

  let totalAmtLength = total_outstanding_amount;

  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let showActiveUser = addSearchParams.get("i");
  const widths = window.innerWidth;

  return (
    <div className="invoices-container --req_modifi">
      <div>
        <div className="invoices-header-container">
          <div className="invoices-header___message-conatiner">
            <div>
              {/* <HeadingXLarge>{customerData?.name}</HeadingXLarge> */}
              <div className="sidebar-top">
                <NavLink
                  to={`/${hash}${showActiveUser ? `?i=${showActiveUser}` : ""}`}
                >
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      paddingLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {widths <= 500 ? (
                        <Avatar
                          overrides={{
                            Avatar: {
                              style: ({ $theme }) => ({
                                borderTopLeftRadius: $theme.borders.radius100,
                                borderTopRightRadius: $theme.borders.radius100,
                                borderBottomRightRadius:
                                  $theme.borders.radius100,
                                borderBottomLeftRadius:
                                  $theme.borders.radius100,
                              }),
                            },
                            Root: {
                              style: ({ $theme }) => ({
                                borderTopLeftRadius: $theme.borders.radius100,
                                borderTopRightRadius: $theme.borders.radius100,
                                borderBottomRightRadius:
                                  $theme.borders.radius100,
                                borderBottomLeftRadius:
                                  $theme.borders.radius100,
                                backgroundColor: "#F8F8FB",
                                width: "48px",
                              }),
                            },
                            Initials: {
                              style: () => ({
                                color: default_color,
                                fontSize: "14px",
                                fontWeight: "700",
                              }),
                            },
                            Avatar: {
                              style: () => ({
                                objectFit: "contain",
                              }),
                            },
                          }}
                          name={organizationData?.name}
                          src={
                            organizationData &&
                            organizationData.customer_portal_ui &&
                            organizationData.customer_portal_ui.logo
                              ? organizationData.customer_portal_ui.logo
                              : null
                          }
                          size="scale1200"
                        />
                      ) : organizationData &&
                        organizationData.customer_portal_ui &&
                        organizationData.customer_portal_ui.logo ? (
                        <div
                          style={{
                            maxWidth: `95px`,
                            minWidth: `18px`,
                            maxHeight: `48px`,
                          }}
                        >
                          <img
                            src={
                              organizationData &&
                              organizationData.customer_portal_ui &&
                              organizationData.customer_portal_ui.logo
                                ? organizationData.customer_portal_ui.logo
                                : null
                            }
                            alt="customer_Logo"
                            className="customer_portal_logo"
                          />
                        </div>
                      ) : (
                        <Avatar
                          overrides={{
                            Avatar: {
                              style: ({ $theme }) => ({
                                borderTopLeftRadius: $theme.borders.radius100,
                                borderTopRightRadius: $theme.borders.radius100,
                                borderBottomRightRadius:
                                  $theme.borders.radius100,
                                borderBottomLeftRadius:
                                  $theme.borders.radius100,
                              }),
                            },
                            Root: {
                              style: ({ $theme }) => ({
                                borderTopLeftRadius: $theme.borders.radius100,
                                borderTopRightRadius: $theme.borders.radius100,
                                borderBottomRightRadius:
                                  $theme.borders.radius100,
                                borderBottomLeftRadius:
                                  $theme.borders.radius100,
                                backgroundColor: "#F8F8FB",
                                width: "48px",
                                objectFit: "contain",
                              }),
                            },
                            Initials: {
                              style: () => ({
                                color: default_color,
                                fontSize: "14px",
                                fontWeight: "700",
                                objectFit: "contain",
                              }),
                            },
                            Avatar: {
                              style: () => ({
                                objectFit: "contain",
                                width: "100px",
                              }),
                            },
                          }}
                          name={organizationData?.name}
                          src={
                            organizationData &&
                            organizationData.customer_portal_ui &&
                            organizationData.customer_portal_ui.logo
                              ? organizationData.customer_portal_ui.logo
                              : null
                          }
                          // size="scale1200"
                        />
                      )}

                      <div className="org-name">
                        <div>
                          <div
                            className="org_name"
                            $style={{ color: "#333860" }}
                          >
                            {organizationData?.name}
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              color: "#787878",
                              display: "flex",
                              gap: "2px",
                              // hap: "10px",
                              flexDirection: `${
                                totalAmtLength &&
                                totalAmtLength.toString().length > 15
                                  ? "column"
                                  : "row"
                              }`,
                            }}
                          >
                            <div className="totl-ots-mob"> Total O/S: </div>
                            <div className="totl_ots-web">
                              {" "}
                              Total Outstanding:{" "}
                            </div>
                            {/* <>Total Outstanding</> */}
                            <NumericFormat
                              displayType="text"
                              value={
                                total_outstanding_amount
                                  ? total_outstanding_amount
                                  : 0
                              }
                              thousandsGroupStyle={currencyGroupStyle}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              thousandSeparator={currencySeparator}
                              decimalSeparator={currencyDecimalSeparator}
                              renderText={(values) => (
                                <div style={{ paddingLeft: "2px" }}>
                                  {currencyList[currency]
                                    ? currencyList[currency]
                                    : currency}{" "}
                                  {values}
                                </div>
                              )}
                            />
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            {/* <div>
            <Link to="/messages">
            <MessageHeaderSvg />
            </Link>
          </div> */}
            <div className="invoices-header___nav-buttons">
              {accessValue?.is_mini_miranda_configured && (
                <div>
                  <div
                    class="button-tilt"
                    onClick={() => {
                      dispatch(setDebtorRightsModal(true));
                    }}
                  >
                    <IconButton kind={KIND.tertiary}>
                      <DebtorRight size={18} />
                      <span class="label-hidden">Consumer Rights</span>
                    </IconButton>
                  </div>
                </div>
              )}
              <EmailUs
                cusData={customizedData}
                organizationData={organizationData}
              />

              {accessValue && accessValue.is_callback && (
                <div>
                  <NavLink
                    to={`/${hash}/requesting-calls${
                      showActiveUser ? `?i=${showActiveUser}` : ""
                    }`}
                  >
                    <div class="button-tilt">
                      <IconButton kind={KIND.tertiary}>
                        <i className="mx-icon-call-back-2" />
                        <span class="label-hidden">Call Back Request</span>
                      </IconButton>
                    </div>
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="back__btn-comp">
          {/* COMPONENTS STARTS */}
          <>
            {data &&
            data?.data &&
            data?.data?.doc?.is_promise_to_pay_pending ? (
              <>
                <div className="rq__call__form__wraper --modifier">
                  <div
                    className="req__show_d"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {/* <div className="rpt-svg-header">
                    <div className="--request-call">
                      <NavLink to={`/${hash}`}>
                        <div
                          style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "50%",
                            border: "0.5px solid #cdced9",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <i className="mx-icon-Group-16969" />
                        </div>
                      </NavLink>
                    </div>
                  
                  </div> */}
                    <div
                      className="none"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "",
                      }}
                    >
                      <div className="--request-call">
                        <NavLink to={`/${hash}`}>
                          <div
                            style={{
                              height: "30px",
                              width: "30px",
                              borderRadius: "50%",
                              border: "0.5px solid #cdced9",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <i className="mx-icon-Group-16969" />
                          </div>
                        </NavLink>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <HeadingXLarge>
                          Schedule a promised payment
                        </HeadingXLarge>
                        <ParagraphMedium $style={{ color: "#ADADAD" }}>
                          Find your promise to pay details
                        </ParagraphMedium>
                      </div>
                    </div>
                    <div>
                      <PromiseToPaySvg />
                    </div>
                    <div>
                      <div className="--promise_details">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            paddingBottom: "10px",
                          }}
                          className="rpt-svg-bottom-header "
                        >
                          <HeadingXLarge>
                            {" "}
                            Schedule a promised payment
                          </HeadingXLarge>
                          <ParagraphMedium $style={{ color: "#ADADAD" }}>
                            Find your promise to pay details
                          </ParagraphMedium>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "space-between",
                          maxWidth: "350px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <ParagraphMedium
                            $style={{ whiteSpace: "noWrap", width: "110px" }}
                          >
                            Amount in :
                          </ParagraphMedium>
                          <ParagraphMedium style={{ whiteSpace: "noWrap" }}>
                            Promise to Pay by :
                          </ParagraphMedium>
                          <ParagraphMedium style={{ whiteSpace: "noWrap" }}>
                            Message:
                          </ParagraphMedium>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <>
                            <NumericFormat
                              displayType="text"
                              value={
                                data?.data?.doc?.request_time_data
                                  ?.promise_amount?.value
                              }
                              thousandsGroupStyle={currencyGroupStyle}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              thousandSeparator={currencySeparator}
                              decimalSeparator={currencyDecimalSeparator}
                              renderText={(values) => (
                                <div style={{ display: "flex" }}>
                                  <ParagraphMedium
                                    $style={{ color: "#333860" }}
                                  >
                                    {values &&
                                    currencyList[customerData.currency]
                                      ? currencyList[customerData.currency]
                                      : customerData.currency}{" "}
                                    {values} {}
                                  </ParagraphMedium>
                                </div>
                              )}
                            />
                          </>
                          <ParagraphMedium>
                            {moment
                              .tz(
                                data?.data?.doc?.request_time_data
                                  ?.promise_date,
                                timeZone
                              )
                              .format(dateFormat)}
                          </ParagraphMedium>
                          <ParagraphMedium>
                            {requestTimeDataList &&
                            requestTimeDataList?.promise_type !== "no_amount"
                              ? `Thanks for your Promise to Pay the above mentioned amount and by the date to ${orgData?.name}.`
                              : "We'll contact you on the specified date regarding your payment."}
                            {}{" "}
                          </ParagraphMedium>
                        </div>
                      </div>
                      <div
                        className="cbd-back"
                        style={{ textAlign: "center", marginTop: "10px" }}
                      >
                        <TextButton
                          kind={KIND.secondary}
                          size={SIZE.compact}
                          startEnhancer={
                            <i className="mx-icon-Group-16179 font-icon-color" />
                          }
                        >
                          <NavLink
                            to={`/${hash}${
                              showActiveUser ? `?i=${showActiveUser}` : ""
                            }`}
                          >
                            Go Back
                          </NavLink>
                        </TextButton>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : data &&
              data?.data &&
              data?.data?.doc?.is_payment_plan_pending ? (
              <PaymentAmount data={data && data?.data?.doc} />
            ) : (
              <PaymentPlan
                data={data && data.data && data.data.doc ? data.data.doc : null}
                setCount={setCount}
                count={count}
              />
            )}

            {/* <PaymentType type={isSelect}/> */}
          </>
          {/* COMPONENTS ENDS */}
        </div>
      </div>
      {data?.data?.doc?.request_time_data?.promise_amount ? (
        <>
          <div className="--pay_for_phone">
            <div
              className="planned_pay"
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div
                className="go_back-btn"
                style={{ width: "250px", paddingTop: "10px" }}
              >
                <TextButton
                  size={SIZE.compact}
                  fullWidth
                  type="button"
                  kind={KIND.primary}
                  onClick={() => {
                    dispatch(
                      paymentAmount(
                        data?.data?.doc?.request_time_data?.promise_amount
                          ?.value
                      )
                    );
                    navigate(
                      `/${hash}/payments${
                        showActiveUser ? `?i=${showActiveUser}` : ""
                      }`
                    );
                  }}
                >
                  Pay now
                </TextButton>
              </div>
            </div>
          </div>

          <div
            className="req_pay_now_container --pay_for_desk"
            style={{
              background: default_color,
            }}
          >
            <div className="req_pay_now_wraper">
              <ParagraphMedium $style={{ color: "#ffffff", fontWeight: "700" }}>
                Promise Amount{" "}
                {currencyList[customerData.currency]
                  ? currencyList[customerData.currency]
                  : customerData.currency}{" "}
                <NumericFormat
                  displayType="text"
                  value={
                    data?.data?.doc?.request_time_data?.promise_amount?.value
                  }
                  thousandsGroupStyle={currencyGroupStyle}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={currencySeparator}
                  decimalSeparator={currencyDecimalSeparator}
                  renderText={(values) => <>{values}</>}
                />
              </ParagraphMedium>
              {/* <NavLink
                to={`/${hash}/payments${
                  showActiveUser ? `?i=${showActiveUser}` : ""
                }`}
              > */}
              <div className="req_pay_now_btn">
                <TextButton
                  size={SIZE.compact}
                  fullWidth
                  type="button"
                  kind={KIND.secondary}
                  onClick={() => {
                    dispatch(
                      paymentAmount(
                        data?.data?.doc?.request_time_data?.promise_amount
                          ?.value
                      )
                    );
                    navigate(
                      `/${hash}/payments${
                        showActiveUser ? `?i=${showActiveUser}` : ""
                      }`
                    );
                  }}
                >
                  Pay now
                </TextButton>
              </div>
              {/* </NavLink> */}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RequestingTime;
