import React from "react";

const EncryptSvg = ({ size = 50 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="24" fill="white" />
      <path
        opacity="0.85"
        d="M20.4885 9.30332H19.1832V6.72743C19.1832 5.42333 18.6569 4.14626 17.7011 3.13134C16.7454 2.11646 15.3998 1.40575 13.9123 1.1302C12.9751 0.956602 12.0249 0.956602 11.0877 1.1302C9.60015 1.40575 8.25456 2.11646 7.29881 3.13134C6.34301 4.14621 5.81672 5.42337 5.81672 6.72743V9.30337H4.51145C4.22902 9.30337 4 9.49961 4 9.74161V20.8219C4 21.0224 4.15876 21.1972 4.38543 21.2466L12.374 22.9865C12.4154 22.9955 12.4577 23 12.5 23C12.5423 23 12.5846 22.9955 12.626 22.9865L20.6146 21.2466C20.8413 21.1973 21 21.0224 21 20.8219V9.74156C21 9.49952 20.771 9.30332 20.4885 9.30332ZM6.83967 6.72739C6.83967 4.45972 8.71712 2.46605 11.3038 1.98682C12.0976 1.83978 12.9024 1.83978 13.6961 1.98682C16.2828 2.46601 18.1603 4.45972 18.1603 6.72739V9.30328H17.0029V6.78338C17.0029 5.90338 16.6473 5.0419 16.0016 4.35762C15.3559 3.67334 14.4473 3.19497 13.4431 3.01064C12.8173 2.89578 12.1827 2.89578 11.5568 3.01064C10.5526 3.19497 9.64393 3.67334 8.99824 4.35762C8.3526 5.0419 7.99701 5.90338 7.99701 6.78338V9.30328H6.83962V6.72739H6.83967ZM15.9801 6.78342V9.30332H9.01991V6.78342C9.01991 5.38646 10.1769 4.16022 11.7711 3.86765C12.2547 3.77892 12.7453 3.77892 13.2288 3.86765C14.8231 4.16022 15.9801 5.38651 15.9801 6.78342ZM19.9771 20.4811L12.5 22.1096L5.0229 20.4811V10.1798H19.9772L19.9771 20.4811Z"
        fill="black"
      />
      <path
        d="M13.9199 16.0252C14.5794 15.6306 14.9885 14.9751 14.9885 14.2744C14.9885 13.0987 13.8722 12.1422 12.5 12.1422C11.1278 12.1422 10.0115 13.0987 10.0115 14.2744C10.0115 14.9751 10.4206 15.6306 11.0802 16.0252L10.5279 18.5432C10.4998 18.6713 10.5397 18.8036 10.6369 18.9039C10.734 19.0043 10.8784 19.0624 11.0305 19.0624H13.9695C14.1217 19.0624 14.2659 19.0043 14.3631 18.9039C14.4603 18.8036 14.5002 18.6713 14.4721 18.5432L13.9199 16.0252ZM12.8482 15.8848L13.3529 18.1859H11.647L12.1517 15.8848C12.1945 15.6895 12.0787 15.494 11.8694 15.4084C11.3621 15.2008 11.0343 14.7557 11.0343 14.2744C11.0343 13.5819 11.6917 13.0186 12.4999 13.0186C13.3081 13.0186 13.9656 13.5819 13.9656 14.2744C13.9656 14.7557 13.6378 15.2008 13.1305 15.4084C12.9213 15.494 12.8055 15.6895 12.8482 15.8848Z"
        fill="black"
      />
      <path
        d="M18.893 10.6858C18.6106 10.6858 18.3815 10.882 18.3815 11.124V13.4905C18.3815 13.7325 18.6106 13.9287 18.893 13.9287C19.1754 13.9287 19.4044 13.7325 19.4044 13.4905V11.124C19.4044 10.882 19.1754 10.6858 18.893 10.6858Z"
        fill="black"
      />
      <path
        d="M18.893 14.4107C18.6106 14.4107 18.3815 14.6069 18.3815 14.8489V15.1118C18.3815 15.3538 18.6106 15.5501 18.893 15.5501C19.1754 15.5501 19.4044 15.3538 19.4044 15.1118V14.8489C19.4044 14.6069 19.1754 14.4107 18.893 14.4107Z"
        fill="black"
      />
    </svg>
  );
};

export default EncryptSvg;
