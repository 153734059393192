import { Accordion, Panel } from "baseui/accordion";
import { HeadingXLarge } from "baseui/typography";
import { useState } from "react";

const FAQSection = ({ faq }) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter FAQs based on search term
  const filteredFaqs = faq.filter((item) =>
    item.q.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="separtor_home_faq_page_desktop">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
        id="faq-section"
      >
        <HeadingXLarge $style={{ marginBottom: "10px" }}>
          Frequently Asked Questions (FAQ)
        </HeadingXLarge>

        {/* Search Input */}
        <input
          type="text"
          placeholder="Search FAQs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
          }}
        />

        <div
          style={{
            height: "410px",
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Accordion
            onChange={({ expanded }) =>
              console.log("Expanded Panel:", expanded)
            }
            accordion
          >
            {filteredFaqs.length > 0 ? (
              filteredFaqs.map((item, index) => (
                <Panel key={index} title={item.q}>
                  {item.a}
                </Panel>
              ))
            ) : (
              <p style={{ textAlign: "center", padding: "10px" }}>
                No FAQs found.
              </p>
            )}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
