import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { HeadingXLarge, LabelLarge } from "baseui/typography";
import React, { useEffect, useState } from "react";
import ChatIcon from "../../assets/img/svg/ChatIcon";
import { useSelector } from "react-redux";
import AuthForm from "./AuthForm";
import SupportWindowDataLayer from "./SupportWindow";
import { isExpired } from "react-jwt";

function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    );
  }
  throw new Error("Bad Hex");
}

function getTextColor(rgba) {
  rgba = rgba.match(/\d+/g);
  if (rgba[0] * 0.299 + rgba[1] * 0.587 + rgba[2] * 0.114 > 186) {
    return "#08083a";
  } else {
    return "white";
  }
}

const ChatBotContainerType = ({ type, isChatBot, setIsChatBot }) => {
  // const authorizeDocAuthToken = localStorage.getItem("AuthorizationToken");

  // useEffect(() => {
  //   if (isExpired(authorizeDocAuthToken)) {
  //     setIsChatBot({ type: "", data: {} });
  //   }
  // }, []);

  switch (type) {
    case "CHAT_BOT":
      return (
        <SupportWindowDataLayer
          isChatBot={isChatBot}
          setIsChatBot={setIsChatBot}
        />
      );
    default:
      return <AuthForm isChatBot={isChatBot} setIsChatBot={setIsChatBot} />;
  }
};

const ChatBot = () => {
  const [isChatBot, setIsChatBot] = useState({ type: null, data: {} });

  const { default_nav_color, organization } = useSelector(
    (s) => s.layoutReduceData
  );

  const rgba = hexToRgbA(default_nav_color);

  const textColor = getTextColor(rgba);

  return (
    <StatefulPopover
      content={({ close, ...rest }) => {
        console.log("REST_MAX_T", rest);
        return (
          <div
            style={{
              height: "calc(100vh - 200px)",
              background: "#ffffff",
              borderRadius: "5px",
              position: "fixed",
              bottom: "5%",
              right: "3%",
              zIndex: "9999",
              background: `linear-gradient(${default_nav_color} 0%, #242020 35%, #f1f0f0 100%)`,
              width: "365px",
              border: "1px solid #cdced9",
            }}
          >
            <div
              style={{
                padding: "20px 10px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#ffffff",
                  padding: "5px",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ChatIcon color={"#333860"} size={22} />
              </div>
              <HeadingXLarge
                $style={{ color: textColor ? textColor : "#ffffff" }}
              >
                Chat with Zoya
              </HeadingXLarge>
            </div>
            <div
              style={{
                backgroundColor: "#ffffff",
                height: "calc(100vh - 293px)",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
              }}
            >
              <ChatBotContainerType
                type={isChatBot.type}
                data={isChatBot.data}
                isChatBot={isChatBot}
                setIsChatBot={setIsChatBot}
              />
            </div>
          </div>
        );
      }}
      placement={PLACEMENT.topRight}
      returnFocus
      autoFocus
    >
      {organization && organization?.enable_artificial_intelegence ? (
        <div
          style={{
            // height: "50px",
            // width: "50px",
            padding: "5px",
            position: "fixed",
            bottom: "5%",
            right: "3%",
            zIndex: "9999",
            backgroundColor: default_nav_color,
            color: "white",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <ChatIcon color={textColor} size={22} />
            <LabelLarge $style={{ color: textColor ? textColor : "#ffffff" }}>
              Ask Zoya
            </LabelLarge>
          </div>
        </div>
      ) : (
        <></>
      )}
    </StatefulPopover>
  );
};

export default ChatBot;
