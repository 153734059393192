import React from "react";
import _ from "lodash";
import { Controller } from "react-hook-form";
import InputComponent from "../AuthorizeDownload/InputComponent";
import { TextButton } from "../../components/TextButton";
import { TextBox } from "../../components/TextBox";
import { useIntl } from "react-intl";
import { LabelMedium, ParagraphMedium } from "baseui/typography";
import CheckBoxBaseweb from "../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { NavLink, useLocation } from "react-router-dom";

const CustomFormUtill = ({
  phoneEmail = {},
  termsCondition,
  alignment = "left",
  textColor = "#ffff",
  privacy,
  rightsConfigContent,
  buttonName,
  watchControl,
  nextCancel,
  stateType,
  customError,
  customControl,
  requestLoading,
  configMapping = {},
  buttonSubmit = {},
}) => {
  const [checked, setChecked] = React.useState(false);
  const intl = useIntl();
  const location = useLocation();
  const hash = location?.pathname?.split("/")[1];
  const handleChange = (eventOrValue, field, fieldType) => {
    const value =
      fieldType === "DT"
        ? eventOrValue?.date // Extract date value
        : eventOrValue?.target?.value; // Extract string value

    console.log(
      fieldType === "DT" ? "Date Changed:" : "String Changed:",
      value
    );
    field.onChange(value); // Update the form state
  };

  console.log(configMapping, "configMapping");

  return (
    <div style={{ textAlign: alignment }}>
      <form onSubmit={buttonSubmit}>
        {stateType !== "auth" ? (
          <>
            <Controller
              name="value"
              control={customControl}
              render={({ field }) => (
                <TextBox
                  {...field}
                  shouldUnregister
                  requiredAstric={true}
                  fullWidth
                  name={field.name}
                  size="mini"
                  label={
                    _.get(phoneEmail, "label", "")
                      ? _.get(phoneEmail, "label", "")
                      : intl.formatMessage({
                          id: "enter_your_phone_or_email",
                        })
                  }
                  placeholder={
                    _.get(phoneEmail, "placeHolder", "")
                      ? _.get(phoneEmail, "placeHolder", "")
                      : intl.formatMessage({
                          id: "enter_your_phone_or_email",
                        })
                  }
                  error={customError?.value?.message}
                  value={field.value}
                />
              )}
            />

            {configMapping?.type === "initial" &&
              configMapping?.data?.length > 0 && (
                <LabelMedium
                  style={{
                    color: textColor,
                    marginBottom: "16px",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  ------------------------ or ------------------------
                </LabelMedium>
              )}
          </>
        ) : (
          <></>
        )}

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
            gap: "5px",
            maxWidth: "520px",
          }}
        >
          {configMapping?.data?.length > 0 &&
            configMapping?.data?.map((field, index) => (
              <div key={index}>
                <Controller
                  name={`${_.get(field, "fm", "").toLowerCase()}_${field?.fk}`}
                  key={`${_.get(field, "fm", "").toLowerCase()}_${field?.fk}`}
                  control={customControl}
                  render={({
                    field: controllerField,
                    fieldState: { error },
                  }) => (
                    <InputComponent
                      {...controllerField}
                      item={field}
                      textColor={textColor}
                      error={error?.message}
                      onChange={(eventOrValue) =>
                        handleChange(eventOrValue, controllerField, field.ft)
                      }
                    />
                  )}
                />
              </div>
            ))}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: alignment,
          }}
        >
          {termsCondition && stateType !== "auth" ? (
            <CheckBoxBaseweb
              checked={checked}
              textColor={textColor}
              onChange={(e) => setChecked(e.target.checked)}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <ParagraphMedium
                $style={{
                  textAlign: "left",
                  lineHeight: "16px",
                  color: textColor,
                  opacity: "0.9",
                }}
              >
                By entering the site, you are agreeing to the
                <a
                  href={termsCondition}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    textDecoration: "underline",
                  }}
                >
                  Terms of Service
                </a>
                and
                <a
                  href={privacy}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: "5px", textDecoration: "underline" }}
                >
                  Privacy Policy
                </a>
                .
              </ParagraphMedium>
            </CheckBoxBaseweb>
          ) : (
            <></>
          )}

          {nextCancel ? (
            <div
              className="frame"
              style={{
                alignItems: "flex-start",
                display: "inline-flex",
                gap: "20px",
                left: "50px",
                position: "relative",
              }}
            >
              <NavLink to={`/${hash}`}>
                <TextButton
                  type="button"
                  kind="tertiary"
                  size="compact"
                  fullWidth
                >
                  Cancel
                </TextButton>
              </NavLink>

              <TextButton type="submit" kind="primary" size="compact">
                Next
              </TextButton>
            </div>
          ) : (
            <TextButton
              type="submit"
              kind="primary"
              size="mini"
              fullWidth={true}
              style={{
                marginTop: "10px",
                width: "150px",
              }}
              disabled={
                stateType !== "auth"
                  ? requestLoading.isLoading ||
                    !checked ||
                    Object.values(watchControl()).every(
                      (value) => value === undefined
                    )
                  : false
              }
            >
              {buttonName}
            </TextButton>
          )}

          <ParagraphMedium $style={{ color: textColor }}>
            {rightsConfigContent}
            <span
              style={{
                fontWeight: "500",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {/* <a
                  href={terms_and_conditions}
                  target={terms_and_conditions}
                  rel="noopener noreferrer"
                >
                  Know your rights
                </a> */}
            </span>
          </ParagraphMedium>
        </div>
      </form>
    </div>
  );
};

export default CustomFormUtill;
