import React from "react";

const AccessProfileSvg = ({ size, color = "#5f6368" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 -960 960 960"
      width={size}
      fill={color}
    >
      <path d="M400-507.31q-49.5 0-82.44-32.56-32.94-32.55-32.94-82.82 0-49.5 32.94-82.44 32.94-32.95 82.44-32.95t82.44 32.95q32.94 32.94 32.94 82.44 0 50.27-32.94 82.82-32.94 32.56-82.44 32.56ZM120-217.08v-57.84q0-28.08 14.04-47.62t40.88-32.23q58.93-26.61 109.27-39.85 50.35-13.23 115.81-13.23h8.42q4.2 0 8.43-.77-2.93 8.62-4.39 15.58-1.46 6.96-2.69 15.96H400q-61.85 0-110.81 10.96-48.96 10.97-100.27 38.43-22.15 11.07-30.15 24.04-8 12.96-8 28.73v27.07h259.77q1.92 8 5.08 15.97 3.15 7.96 7 14.8H120Zm540.85 20.93-4.62-49.08q-19.31-3.46-37.19-13.35-17.89-9.88-29.89-24.19l-41.92 14.31-10.38-16.62 37-30.15Q568-329.62 568-349.46q0-19.85 5.85-35l-36.23-30.92L548-432l41.15 15.08q11.23-15.08 29.5-24.58 18.27-9.5 37.58-12.96l4.62-49.08h21.69l4.61 49.08q18.54 3.46 36.81 12.96 18.27 9.5 30.27 23.81L795.38-432l9.62 15.85-36.23 30.92q6.61 16.17 6.61 35.51 0 19.34-6.61 34.49l37 30.15-9.62 16.62-41.92-14.31q-12.77 14.31-30.65 24.19-17.89 9.89-36.43 13.35l-4.61 49.08h-21.69Zm10.14-81q32.09 0 52.16-20.53 20.08-20.53 20.08-51.85 0-32.09-20.14-52.16-20.15-20.08-52.23-20.08-31.32 0-51.78 20.15-20.46 20.14-20.46 52.23 0 31.31 20.53 51.77 20.52 20.47 51.84 20.47ZM400-538.08q35.92 0 60.27-23.96t24.35-60.65q0-35.93-24.35-60.27-24.35-24.35-60.27-24.35t-60.27 24.35q-24.35 24.34-24.35 60.27 0 36.69 24.35 60.65T400-538.08Zm0-84.61Zm10.54 374.84Z" />
    </svg>
  );
};

export default AccessProfileSvg;
