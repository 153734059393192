import React from "react";

const DownloadReciptSvg = ({ size = "48", color = "#5f6368" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 -960 960 960"
      width={size}
      fill={color}
    >
      <path d="M610.62-518.77 480-388.92 349.38-518.77l22-22 93.24 93.23v-204.77h30.76v204.77l93.24-93.23 22 22ZM408-158.08h144v-30.77H408v30.77ZM295.38-80q-23.05 0-39.22-16.16Q240-112.33 240-135.38v-689.24q0-23.05 16.16-39.22Q272.33-880 295.38-880h369.24q23.05 0 39.22 16.16Q720-847.67 720-824.62v689.24q0 23.05-16.16 39.22Q687.67-80 664.62-80H295.38Zm-24.61-155.38v100q0 9.23 7.69 16.92 7.69 7.69 16.92 7.69h369.24q9.23 0 16.92-7.69 7.69-7.69 7.69-16.92v-100H270.77Zm0-30.77h418.46v-496.93H270.77v496.93Zm0-527.7h418.46v-30.77q0-9.23-7.69-16.92-7.69-7.69-16.92-7.69H295.38q-9.23 0-16.92 7.69-7.69 7.69-7.69 16.92v30.77Zm0 558.47v124.61-124.61Zm0-558.47V-849.23v55.38Z" />
    </svg>
  );
};

export default DownloadReciptSvg;
