import { HeadingXLarge, LabelLarge, LabelMedium } from "baseui/typography";
import React, { useState, useMemo, useEffect } from "react";
import TickSvg from "../../assets/img/svg/TickSvg";
import { useParams, useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import MOCK_DATA from "../Invoices/MOCK_DATA.json";
import { CREDITCOLUMNS } from "./creditColumns";
import TextButton from "../../components/TextButton/TextButton";
import { SIZE } from "baseui/button";
import MessageHeaderSvg from "../../assets/img/svg/MessageHeaderSvg";
import { getGridStructure } from "../../services/gridStructure";
import columnMapper from "../../utils/mapper";
import axios from "axios";
import { getCreditNotes } from "../../services/customerPortal";
import { useQuery } from "react-query";
import ChipFilter from "../../components/ChipFilter";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Avatar } from "baseui/avatar";
import { IconButton } from "../../components/IconButton";
import { KIND } from "baseui/button";
import DebtorRight from "../../assets/img/svg/DebtorRight";
import { setDebtorRightsModal } from "../../redux/Layout/action";
import EmailUs from "../../components/EmailUs/EmailUs";

const CreditNote = () => {
  const { id } = useParams();
  const [columnData, setColumnData] = useState([]);
  const [queryFilter, setQueryFilter] = useState([]);
  const [querySortBy, setQuerySortBy] = useState("created_at");
  let customerData = useSelector((e) => e.layoutReduceData?.customer);
  let orgName = useSelector((e) => e.layoutReduceData?.organization.name);
  const location = useLocation();
  const dispatch = useDispatch();
  let organizationData = useSelector((e) => e.layoutReduceData.organization);

  const hash = location.pathname.split("/")[1];
  let accessValue = useSelector((e) => e.layoutReduceData.accessData);
  const customizedData = useSelector((state) => state?.customizeReducer?.data);

  function sendEmail() {
    if (_.get(customizedData, "em_conf", "")) {
      window.location.assign(`mailto:${_.get(customizedData, "em_conf", "")}`);
    } else {
      window.location.assign(
        `mailto:${organizationData && organizationData.email}`
      );
    }
  }
  // const columns = useMemo(() => CREDITCOLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);

  const [hideAmount, setHideAmount] = React.useState(window.innerWidth);

  function hideData() {
    setHideAmount(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener("resize", hideData);
    return () => window.removeEventListener("resize", hideData);
  }, [hideAmount]);

  useEffect(() => {
    const userListApi = async () => {
      let userListVal = await getGridStructure({
        menu: "credit_note_customer_portal",
      });
      setColumnData(userListVal.data.doc.table);
    };
    userListApi();
  }, []);

  const { data, isFetched, isError } = useQuery(
    [
      `CREDIT_NOTE`,
      {
        filters: queryFilter,
        sort: querySortBy,
      },
    ],
    async ({ queryKey }) => {
      let { sort, filters } = queryKey[1];
      return await getCreditNotes({ sort, ...filters });
    }
  );

  const updateQueryFilter = (activeFilters) => {
    if (activeFilters.length > 0) {
      setQueryFilter({
        type: [...activeFilters],
      });
    } else {
      setQueryFilter({});
    }
  };

  if (hideAmount <= 412) {
    const columns = columnData.splice(1, 1);
  }

  const columns = useMemo(() => {
    let { columns, hiddenColumns } = columnMapper({
      columns: columnData,
      isExpanded: false,
      isSelection: false,
    });
    return columns;
  }, [columnData]);

  const tabledata = useMemo(
    () => (isFetched && !isError && data?.data.docs ? data.data.docs : []),
    [isFetched, data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    state: { pageIndex, pageSize, sortBy, filters },
    prepareRow,
    setHiddenColumns,
    setColumnOrder,
    allColumns,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: tabledata,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );

  const { default_color } = useSelector((s) => s.layoutReduceData);

  useEffect(() => {
    setQuerySortBy(
      sortBy[0]
        ? sortBy[0].desc
          ? "-" + sortBy[0].id
          : "" + sortBy[0].id
        : "created_at"
    );
  }, [sortBy, filters]);

  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let showActiveUser = addSearchParams.get("i");
  const widths = window.innerWidth;
  return (
    <div className="invoices-container">
      <div className="invoices-header-container">
        <div className="invoices-header___message-conatiner">
          <div>
            {/* <HeadingXLarge>{customerData?.name}</HeadingXLarge> */}
            <div className="sidebar-top">
              <div
                style={{
                  textAlign: "left",
                  width: "100%",
                  paddingLeft: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    // width: "450px",
                  }}
                >
                  {widths <= 500 ? (
                    <Avatar
                      overrides={{
                        Avatar: {
                          style: ({ $theme }) => ({
                            borderTopLeftRadius: $theme.borders.radius100,
                            borderTopRightRadius: $theme.borders.radius100,
                            borderBottomRightRadius: $theme.borders.radius100,
                            borderBottomLeftRadius: $theme.borders.radius100,
                          }),
                        },
                        Root: {
                          style: ({ $theme }) => ({
                            borderTopLeftRadius: $theme.borders.radius100,
                            borderTopRightRadius: $theme.borders.radius100,
                            borderBottomRightRadius: $theme.borders.radius100,
                            borderBottomLeftRadius: $theme.borders.radius100,
                            backgroundColor: "#F8F8FB",
                            width: "48px",
                          }),
                        },
                        Initials: {
                          style: () => ({
                            color: default_color,
                            fontSize: "14px",
                            fontWeight: "700",
                          }),
                        },
                        Avatar: {
                          style: () => ({
                            objectFit: "contain",
                          }),
                        },
                      }}
                      name={organizationData?.name}
                      src={
                        organizationData &&
                        organizationData.customer_portal_ui &&
                        organizationData.customer_portal_ui.logo
                          ? organizationData.customer_portal_ui.logo
                          : null
                      }
                      size="scale1200"
                    />
                  ) : organizationData &&
                    organizationData.customer_portal_ui &&
                    organizationData.customer_portal_ui.logo ? (
                    <div
                      style={{
                        maxWidth: `95px`,
                        minWidth: `18px`,
                        maxHeight: `48px`,
                      }}
                    >
                      <img
                        src={
                          organizationData &&
                          organizationData.customer_portal_ui &&
                          organizationData.customer_portal_ui.logo
                            ? organizationData.customer_portal_ui.logo
                            : null
                        }
                        alt="customer_Logo"
                        className="customer_portal_logo"
                      />
                    </div>
                  ) : (
                    <Avatar
                      overrides={{
                        Avatar: {
                          style: ({ $theme }) => ({
                            borderTopLeftRadius: $theme.borders.radius100,
                            borderTopRightRadius: $theme.borders.radius100,
                            borderBottomRightRadius: $theme.borders.radius100,
                            borderBottomLeftRadius: $theme.borders.radius100,
                          }),
                        },
                        Root: {
                          style: ({ $theme }) => ({
                            borderTopLeftRadius: $theme.borders.radius100,
                            borderTopRightRadius: $theme.borders.radius100,
                            borderBottomRightRadius: $theme.borders.radius100,
                            borderBottomLeftRadius: $theme.borders.radius100,
                            backgroundColor: "#F8F8FB",
                            width: "48px",
                            objectFit: "contain",
                          }),
                        },
                        Initials: {
                          style: () => ({
                            color: default_color,
                            fontSize: "14px",
                            fontWeight: "700",
                            objectFit: "contain",
                          }),
                        },
                        Avatar: {
                          style: () => ({
                            objectFit: "contain",
                            // width: "100px",
                          }),
                        },
                      }}
                      name={organizationData?.name}
                      src={
                        organizationData &&
                        organizationData.customer_portal_ui &&
                        organizationData.customer_portal_ui.logo
                          ? organizationData.customer_portal_ui.logo
                          : null
                      }
                      // size="scale1200"
                    />
                  )}
                  {/* <Avatar
                    overrides={{
                      Root: {
                        style: ({ $theme }) => ({
                          backgroundColor: "#F8F8FB",
                          borderRadius: "5px",
                        }),
                      },
                      Initials: {
                        style: () => ({
                          color: default_color,
                          fontSize: "14px",
                          fontWeight: "700",
                        }),
                      },
                    }}
                    name={organizationData?.name}
                    // src={organizationData}
                    size='364px'
                  /> */}

                  <div
                    // style={{
                    //   display: "flex",
                    //   flexDirection: "column",
                    //   width: "100%",
                    // }}
                    className="org-name"
                  >
                    <div>
                      <LabelLarge $style={{ color: "#333860" }}>
                        {organizationData?.name}
                      </LabelLarge>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ color: "#787878" }}>
                        Total Outstanding:{" "}
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div>
            <Link to="/messages">
            <MessageHeaderSvg />
            </Link>
          </div> */}
          <div className="invoices-header___nav-buttons">
            {accessValue?.is_mini_miranda_configured && (
              <div>
                <div
                  class="button-tilt"
                  onClick={() => {
                    dispatch(setDebtorRightsModal(true));
                  }}
                >
                  <IconButton kind={KIND.tertiary}>
                    <DebtorRight size={18} />
                    <span class="label-hidden">Consumer Rights</span>
                  </IconButton>
                </div>
              </div>
            )}
            <EmailUs
              cusData={customizedData}
              organizationData={organizationData}
            />

            {accessValue && accessValue.is_request_back && (
              <div>
                <NavLink
                  to={`/${hash}/requesting-times${
                    showActiveUser ? `?i=${showActiveUser}` : ""
                  }`}
                >
                  <div class="button-tilt">
                    <IconButton kind={KIND.tertiary}>
                      <i className="mx-icon-Vector-4" />
                      <span class="label-hidden">
                        Schedule a promised payment
                      </span>
                    </IconButton>
                  </div>
                </NavLink>
              </div>
            )}

            {accessValue && accessValue.is_callback && (
              <div>
                <NavLink
                  to={`/${hash}/requesting-calls${
                    showActiveUser ? `?i=${showActiveUser}` : ""
                  }`}
                >
                  <div class="button-tilt">
                    <IconButton kind={KIND.tertiary}>
                      <i className="mx-icon-call-back-2" />
                      <span class="label-hidden">Call Back Request</span>
                    </IconButton>
                  </div>
                </NavLink>
              </div>
            )}
          </div>
        </div>
        {/* <div className="invoices-title">
          <HeadingXLarge>
            Your Payables for{" "}
            <span className="invoices-title__color">
              {orgName ? orgName : " "}
            </span>
          </HeadingXLarge>
        </div> */}
        <div className="invoices-filters">
          <div style={{ paddingLeft: "20px" }}>
            <ChipFilter
              filters={[
                { label: "Unadjusted", value: "unadjusted" },
                { label: "Adjusted", value: "adjusted" },
              ]}
              setFilter={updateQueryFilter}
            />
          </div>
        </div>
      </div>
      <div style={{ height: "95%", overflow: "scroll" }}>
        <div className="invoice-table-wraper">
          <div
            className="customer-profile-tab__table"
            style={{ gridColumn: "1/6", gridRow: "2/13", overflowY: "unset" }}
          >
            <div className="cp-tab__table-wrapper" {...getTableProps()}>
              <div className="cp-tab__table-header">
                {headerGroups.map((headerGroup) => (
                  <>
                    <div
                      className="cp-tab__table-header-wrapper"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      <div className="cp-tab__table-header-content">
                        {headerGroup.headers.map((column, index) => (
                          <div className="cp-tab__table-header-cell-content">
                            <div
                              className="cp-tab__table-header-cell"
                              {...column.getHeaderProps()}
                            >
                              {column.render("Header")}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ))}
              </div>

              <div className="cp-tab__table-body" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  const rowProps = row.getRowProps();
                  return (
                    <React.Fragment key={rowProps.key}>
                      <div
                        className="cp-tab__table-body-content"
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <div
                              className="cp-tab__table-body-cell"
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="req-call__send-btn">
        <LabelMedium $style={{ marginRight: "30px" }}>$ 90000.00</LabelMedium>
        <div style={{ width: "126px", marginRight: "20px" }}>
          <TextButton fullWidth size={SIZE.compact}>
            Pay now
          </TextButton>
        </div>
      </div> */}
    </div>
  );
};

export default CreditNote;
