import React, { useState } from "react";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { useSelector } from "react-redux";

export const TextBox = (props) => {
  const {
    error = "",
    children,
    value = "",
    label = "",
    borderRadiusOverride = "",
  } = props;

  const [isFocused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };
  const { default_color } = useSelector((s) => s.layoutReduceData);
  return (
    <FormControl
      error={error}
      overrides={{
        Caption: {
          style: ({ $theme }) => ({
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "12px",
            textAlign: "left",
          }),
        },
      }}
    >
      <div className="mx-input -changes">
        <label
          htmlFor={props.name}
          className={`mx-input__label ${
            isFocused ? "mx-input__label--active text-primary" : ""
          } ${
            !isFocused && value && value.toString().length > 0
              ? "mx-input__label--active"
              : ""
          } ${error ? "text-error" : ""}`}
        >
          {label}
        </label>
        <Input
          id={props.name}
          autoComplete="off"
          autoFill="off"
          overrides={{
            Root: {
              style: ({ $theme, $isFocused, $error }) => ({
                borderBottomRightRadius: "5px",
                borderBottomLeftRadius: "5px",
                borderTopRightRadius: "5px",
                borderTopLeftRadius: "5px",
                borderRadius: borderRadiusOverride,
                borderWidth: "1px",
                borderColor: $isFocused
                  ? $error
                    ? "#FD372A"
                    : default_color
                  : $error
                  ? "#FD372A"
                  : "#CDCED9",
                backgroundColor: "#ffffff",
                paddingRight: "0px",
                paddingLeft: "0px",
              }),
            },
            Input: {
              style: ({ $theme, $isFocused }) => ({
                backgroundColor: "#ffffff",
                "::placeholder": {
                  color: $isFocused ? "#cbd5e0" : "transparent",
                  transition: "color 0.4s ease",
                },
              }),
            },
            EndEnhancer: {
              style: ({ $theme }) => ({
                backgroundColor: "#FFFFFF",
              }),
            },
          }}
          {...props}
          onFocus={(e) => {
            if (props && props.type === "number") {
              e.target.addEventListener(
                "wheel",
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              );
            }
            if (props.onFocus) {
              props.onFocus();
            }
            onFocus();
          }}
          onBlur={() => {
            if (props.onBlur) {
              props.onBlur();
            }
            onBlur();
          }}
        >
          {children}
        </Input>
      </div>
    </FormControl>
  );
};

export const inputOverrides = {
  Root: {
    style: () => ({
      borderRadius: "5px",
      borderWidth: "1px",
      paddingTop: "1px",
      paddingBottom: "1px",
      paddingRight: "0px",
    }),
  },
};
