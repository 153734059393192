export const LAYOUT_DATA = "LAYOUT_DATA";
export const REFETCH_LAYOUT = "REFETCH_LAYOUT";
export const DEFAULT_COLOR = "DEFAULT_COLOR";
export const DEFAULT_NAV_BAR_COLOR = "DEFAULT_NAV_BAR_COLOR";
export const TAB_MOTION = "TAB_MOTION";
export const MODAL_OPEN = "MODAL_OPEN";
export const PAYMENT_AMOUNT = "PAYMENT_AMOUNT";
export const DEBTOR_RIGHTS_MODAL = "DEBTOR_RIGHTS_MODAL";
export const ESIGN_MODAL = "ESIGN_MODAL";
export const ESIGN_MODAL_STORE = "ESIGN_MODAL_STORE";
export const DATE_FORMAT_LAYOUT = "DATE_FORMAT_LAYOUT";
export const tabMotionChange = (data) => {
  return {
    type: TAB_MOTION,
    payload: data,
  };
};

export const defaultColor = (data) => {
  return {
    type: DEFAULT_COLOR,
    payload: data,
  };
};

export const getLayoutData = (data) => {
  return {
    type: LAYOUT_DATA,
    payload: data,
  };
};
export const getDateFormat = (data) => {
  return {
    type: DATE_FORMAT_LAYOUT,
    payload: data,
  };
};

export const refetachLayoutData = () => {
  return {
    type: REFETCH_LAYOUT,
  };
};

export const modalSetFn = (data) => {
  return {
    type: MODAL_OPEN,
    payload: data,
  };
};

export const paymentAmount = (data) => {
  return {
    type: PAYMENT_AMOUNT,
    payload: data,
  };
};

export const setDebtorRightsModal = (data) => {
  return {
    type: DEBTOR_RIGHTS_MODAL,
    payload: data,
  };
};

export const setESignModal = (data) => {
  return {
    type: ESIGN_MODAL,
    payload: data,
  };
};

export const setESignModalStore = (data) => {
  return {
    type: ESIGN_MODAL_STORE,
    payload: data,
  };
};
